:root, [data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #dee2e680;
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--bs-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--bs-border-width));
  padding-bottom: calc(.375rem + var(--bs-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--bs-border-width));
  padding-bottom: calc(.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--bs-border-width));
  padding-bottom: calc(.25rem + var(--bs-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--bs-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  margin: -.375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--bs-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  color: var(--bs-body-color);
  border: solid #0000;
  border-width: var(--bs-border-width) 0;
  background-color: #0000;
  width: 100%;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  width: 3rem;
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  border-radius: var(--bs-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--bs-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--bs-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

:-webkit-any(.form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:not(:placeholder-shown)), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

:is(.form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:not(:placeholder-shown)), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--bs-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--bs-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius)  - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: .125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  color: var(--bs-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed, .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--bs-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: .5;
  --bs-btn-close-hover-opacity: .75;
  --bs-btn-close-focus-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: .25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--bs-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  opacity: var(--bs-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg)  - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg)  - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform .3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  transition: var(--bs-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  line-height: var(--bs-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .25rem !important;
  column-gap: .25rem !important;
}

.column-gap-2 {
  -moz-column-gap: .5rem !important;
  column-gap: .5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.button-action.transparent {
  border: 1px solid;
  border-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.light {
  border: 1px solid;
  gap: 10px;
  padding: 6px 17px;
}

.button-action.light.whatsapp {
  border-color: var(--neutralwhite);
}

.button-action.google-agenda.dark {
  border-color: #d34e25;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.primary.disabled {
  background-color: #abc7ff;
}

.button-action.light.primary {
  border-color: var(--neutralwhite);
}

.button-action.dark.outlook {
  border-color: #2783d8;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.tertiary.dark {
  background-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.whatsapp.dark {
  border-color: #25d366;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.initial.primary {
  background-color: var(--primary-1);
}

.button-action.light.messenger, .button-action.light.disabled {
  border-color: var(--neutralwhite);
}

.button-action.dark.secondary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.google-agenda.light {
  border-color: var(--neutralwhite);
}

.button-action.dark.primary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.initial.secondary {
  border-color: var(--primary-1);
}

.button-action.light.outlook, .button-action.light.tertiary {
  border-color: var(--neutralwhite);
}

.button-action.dark.messenger {
  border-color: #267cef;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.secondary.dark.disabled {
  border-color: var(--neutraldark-grey);
}

.button-action.light.secondary .connecter-whatsapp, .button-action.tertiary.dark .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.tertiary.transparent .connecter-whatsapp, .button-action.transparent.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.light.tertiary .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.secondary.dark.disabled .connecter-whatsapp {
  color: var(--neutraldark-grey);
}

.button-action.initial.dark.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.primary .connecter-whatsapp a {
  color: #fff;
}

.icon-time-calendar {
  cursor: pointer;
  background-image: url("icon-time-calendar-2.d7f22a24.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.logo.default {
  width: 86px;
  height: 18px;
}

.logo.variant-2 {
  width: 17px;
  height: 16px;
}

.profile-picture {
  background-position: 50%;
  background-size: cover;
}

.profile-picture .SL {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 21px;
  position: absolute;
}

.profile-picture.small {
  width: 40px;
  height: 40px;
}

.profile-picture.medium {
  width: 60px;
  height: 60px;
}

.profile-picture.empty {
  position: relative;
}

.profile-picture.big {
  width: 100px;
  height: 100px;
}

.profile-picture.image.big {
  background-image: url("ellipse-1-1.7ca22cd7.png");
}

.profile-picture.small.empty {
  background-image: url("ellipse-1-6.c8801d6a.png");
}

.profile-picture.small.image {
  background-image: url("ellipse-1-5.5ec6408e.png");
}

.profile-picture.big.empty {
  background-image: url("ellipse-1-2.d4c3f56d.png");
}

.profile-picture.image.medium {
  background-image: url("ellipse-1-3.d1e35db9.png");
}

.profile-picture.medium.empty {
  background-image: url("ellipse-1-4.122bae27.png");
}

.profile-picture.medium .SL {
  top: 19px;
  left: 17px;
}

.profile-picture.big .SL {
  top: 39px;
  left: 37px;
}

.profile-picture.small .SL {
  top: 9px;
  left: 7px;
}

.header-mobile {
  background-color: var(--neutralwhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--neutralgrey);
  width: 393px;
  height: 69px;
  position: relative;
}

.header-mobile .class {
  top: 25px !important;
  left: 144px !important;
}

.header-mobile .class-2 {
  top: 25px !important;
  left: 24px !important;
}

.header-mobile .profile-picture-instance {
  position: absolute !important;
  top: 15px !important;
  left: 295px !important;
}

.header-mobile .icon-arrow-arrow-2 {
  background-image: url("icon-arrow-arrow-left.ae87d30e.svg");
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 18px;
  left: 22px;
}

.header-mobile .calendrier {
  color: var(--neutralblack);
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  white-space: nowrap;
  height: 27px;
  position: absolute;
  top: 21px;
  left: 151px;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  z-index: 99;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  -ms-align-items: center;
  opacity: 0;
  background: #ffffffe6;
  justify-content: center;
  align-items: center;
  animation: .5s .2s forwards react-confirm-alert-fadeIn;
  display: flex;
  position: fixed;
  inset: 0;
}

.react-confirm-alert-body {
  text-align: left;
  color: #666;
  background: #fff;
  border-radius: 10px;
  width: 400px;
  padding: 30px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 20px 75px #00000021;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  justify-content: flex-start;
  margin-top: 20px;
  display: flex;
}

.react-confirm-alert-button-group > button {
  color: #eee;
  cursor: pointer;
  background: #333;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  padding: 6px 18px;
  font-size: 12px;
  display: inline-block;
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-indicator {
  width: 375px;
  height: 34px;
  position: relative;
}

.home-indicator .div {
  background-color: var(--label-color-dark-primary);
  border-radius: 100px;
  width: 134px;
  height: 5px;
  position: relative;
  top: 21px;
  left: 121px;
}

.home-indicator .mode-dark {
  height: 34px;
}

.home-indicator .mode-light {
  background-color: var(--label-color-light-primary);
  border-radius: 100px;
  width: 134px;
  height: 5px;
  position: relative;
  top: 21px;
  left: 121px;
}

.text-field-code {
  align-items: flex-start;
  gap: 7px;
  width: fit-content;
  display: flex;
  position: relative;
}

.text-field-code .text-field-number-caps {
  width: unset !important;
  min-width: 46px !important;
}

.text-field-code .text-field-number-caps input {
  text-align: center;
  border-radius: 8px;
  height: 46px;
  font-size: 25px;
  width: unset !important;
  max-width: 46px !important;
}

.text-field-basic input {
  border: 2px solid #80808047;
  border-radius: 8px;
  width: 243px;
  height: 52px;
  position: relative;
  overflow: hidden;
  width: 340px !important;
}

.text-field-basic .label {
  position: absolute;
}

.text-field-basic .text-wrapper {
  position: absolute;
  left: 8px;
}

.text-field-basic .div {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 96px;
  left: 8px;
}

.text-field-basic .icon-arrow-arrow {
  background-image: url("icon-arrow-arrow-down.3c0243e6.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 202px;
}

.text-field-basic .label-2 {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 8px;
}

.text-field-basic.text-area .label {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  white-space: nowrap;
  width: 105px;
  height: 11px;
  top: 6px;
  left: 8px;
}

.text-field-basic.classic .label {
  color: var(--neutraldark-grey);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  white-space: nowrap;
  height: 18px;
  top: 2px;
  left: 8px;
}

.text-field-basic.dropdown .label {
  background-image: url("icon-arrow-arrow-down.3c0243e6.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  top: 13px;
  left: 202px;
}

.text-field-basic.active .text-wrapper {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
  white-space: nowrap;
}

.text-field-basic.initial .text-wrapper {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  height: 24px;
  top: 12px;
}

.text-field-basic.dropdown .text-wrapper {
  white-space: nowrap;
  height: 24px;
  top: 12px;
}

.text-field-basic.filled .text-wrapper {
  color: var(--neutralblack);
  height: 24px;
}

.text-field-basic.classic .text-wrapper {
  white-space: nowrap;
}

.text-field-basic.filled.text-area .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 225px;
  top: 20px;
}

.text-field-basic.filled.dropdown .text-wrapper, .text-field-basic.transparent.filled .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.classic.active .text-wrapper {
  height: 18px;
  top: 2px;
}

.text-field-basic.active.text-area .text-wrapper {
  width: 105px;
  height: 11px;
  top: 6px;
}

.text-field-basic.classic.filled .text-wrapper {
  top: 20px;
}

.text-field-basic.classic.white.filled .text-wrapper {
  font-family: var(--label-initial-font-family);
  font-size: var(--label-initial-font-size);
  font-style: var(--label-initial-font-style);
  font-weight: var(--label-initial-font-weight);
  letter-spacing: var(--label-initial-letter-spacing);
  line-height: var(--label-initial-line-height);
}

.inscription-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.inscription-mobile .div-3 {
  background-color: #fff;
  border: 0;
  width: 393px;
  height: 1100px;
  position: relative;
  overflow: hidden;
}

.inscription-mobile .p {
  color: #000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  width: 263px;
  position: absolute;
  top: 94px;
  left: 57px;
}

.inscription-mobile .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 263px;
  position: absolute;
  top: 186px;
  left: 28px;
}

.inscription-mobile .text-wrapper-3 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 263px;
  position: absolute;
  top: 472px;
  left: 28px;
}

.inscription-mobile .text-field-basic-instance {
  width: 340px !important;
  position: absolute !important;
  top: 236px !important;
  left: 17px !important;
}

.inscription-mobile .design-component-instance-node {
  width: 340px !important;
  position: absolute !important;
  top: 304px !important;
  left: 17px !important;
}

.inscription-mobile .text-field-basic-2 {
  width: 340px !important;
  position: absolute !important;
  top: 370px !important;
  left: 17px !important;
}

.inscription-mobile .text-field-basic-3 {
  width: 340px !important;
  position: absolute !important;
  top: 521px !important;
  left: 17px !important;
}

.inscription-mobile .text-field-basic-4 {
  width: 340px !important;
  position: absolute !important;
  top: 584px !important;
  left: 17px !important;
}

.inscription-mobile .en-cr-ant-votre {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 771px;
  left: 52px;
}

.inscription-mobile .span {
  color: #aeaeae;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile .text-wrapper-4 {
  color: #4282ff;
  font-family: var(--CP-bold-font-family);
  font-size: var(--CP-bold-font-size);
  font-style: var(--CP-bold-font-style);
  font-weight: var(--CP-bold-font-weight);
  letter-spacing: var(--CP-bold-letter-spacing);
  line-height: var(--CP-bold-line-height);
}

.inscription-mobile .text-wrapper-5 {
  color: #4282ff;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile .button-action-instance {
  z-index: 1;
  width: 341px !important;
  position: absolute !important;
  top: 1030px !important;
  left: 17px !important;
}

.inscription-mobile .keyboard {
  backdrop-filter: blur(108.73px) brightness();
  background-color: #fff;
  width: 375px;
  height: 291px;
  position: absolute;
  top: 1000px;
  left: 9px;
}

.inscription-mobile .overlap {
  height: 283px;
  position: relative;
  top: 8px;
  left: 1px;
}

.inscription-mobile .home-indicator-instance {
  position: absolute !important;
  top: 249px !important;
  left: 0 !important;
}

.inscription-mobile .keys {
  width: 369px;
  height: 259px;
  position: absolute;
  top: 0;
  left: 2px;
}

.inscription-mobile .dictation {
  width: 15px;
  height: 25px;
  position: absolute;
  top: -2531px;
  left: -3044px;
}

.inscription-mobile .emoji {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -2532px;
  left: -3349px;
}

.inscription-mobile .overlap-group {
  width: 182px;
  height: 42px;
  position: absolute;
  top: -2772px;
  left: -3374px;
}

.inscription-mobile .return {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 88px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile .label-3 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 88px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile .space {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 182px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile .label-4 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 182px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile .element {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 87px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile .label-5 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 87px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile .delete {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 327px;
}

.inscription-mobile .key-light {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: -2880px;
  left: -3701px;
}

.inscription-mobile .label-6 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 42px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile .delete-button {
  width: 23px;
  height: 17px;
  position: absolute;
  top: -2751px;
  left: -3361px;
}

.inscription-mobile .shift {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 0;
}

.inscription-mobile .overlap-group-wrapper {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile .symbol-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 44px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.inscription-mobile .symbol {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 21px;
}

.inscription-mobile .shift-2 {
  width: 19px;
  height: 16px;
  position: absolute;
  top: -2752px;
  left: -3359px;
}

.inscription-mobile .m {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 282px;
}

.inscription-mobile .div-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 34px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.inscription-mobile .symbol-2 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 7px;
}

.inscription-mobile .n {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 244px;
}

.inscription-mobile .symbol-3 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 9px;
}

.inscription-mobile .b {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 206px;
}

.inscription-mobile .symbol-4 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 10px;
}

.inscription-mobile .v {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 169px;
}

.inscription-mobile .c {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 131px;
}

.inscription-mobile .x {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 94px;
}

.inscription-mobile .z {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 56px;
}

.inscription-mobile .l {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 319px;
}

.inscription-mobile .symbol-5 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 11px;
}

.inscription-mobile .k {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 281px;
}

.inscription-mobile .j {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 244px;
}

.inscription-mobile .h {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 206px;
}

.inscription-mobile .g {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 169px;
}

.inscription-mobile .f {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 131px;
}

.inscription-mobile .d {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 94px;
}

.inscription-mobile .s {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 56px;
}

.inscription-mobile .a {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 19px;
}

.inscription-mobile .overlap-wrapper {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 337px;
}

.inscription-mobile .o {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 300px;
}

.inscription-mobile .symbol-6 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 8px;
}

.inscription-mobile .i {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 262px;
}

.inscription-mobile .symbol-7 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 14px;
}

.inscription-mobile .u {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 225px;
}

.inscription-mobile .y {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 187px;
}

.inscription-mobile .t {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 150px;
}

.inscription-mobile .r {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 112px;
}

.inscription-mobile .e {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 75px;
}

.inscription-mobile .w {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 37px;
}

.inscription-mobile .symbol-8 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 6px;
}

.inscription-mobile .q {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile .header-mobile-instance {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.cgu.patient {
  width: 341px !important;
  position: absolute !important;
  top: 717px !important;
  left: 17px !important;
}

.info.patient {
  width: 341px !important;
  position: absolute !important;
  top: 866px !important;
  left: 17px !important;
}

.cgu.patient a {
  color: #0d6efd;
  cursor: pointer;
}

.info.patient a {
  color: #0d6efd;
}

.inscription-mobile .logout {
  display: none;
}

.password-generator .modal-content {
  margin-top: 145px;
  width: 90% !important;
}

.password-generator .password-copied {
  text-align: left;
  font-size: 12px;
}

.password-generator .confirm-button .btn {
  width: 124px;
  margin-top: 30px;
  margin-right: 6px;
}

.password-generator #generate {
  margin-bottom: 42px;
  font-size: 10px;
  color: #fff !important;
}

.password-generator {
  width: 340px !important;
  position: absolute !important;
  top: 510px !important;
  left: 17px !important;
}

.tabs-tab-item {
  width: 169px;
  height: 52px;
  position: relative;
}

.tabs-tab-item .line {
  width: 169px;
  height: 2px;
  position: absolute;
  top: 50px;
  left: 0;
}

.tabs-tab-item .frame {
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  display: flex;
  position: absolute;
  top: 13px;
  left: 38px;
}

.tabs-tab-item .observance {
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.tabs-tab-item .img {
  object-fit: cover;
  width: 169px;
  height: 0;
  position: absolute;
  top: -5145px;
  left: -13841px;
}

.tabs-tab-item.inactive {
  overflow: hidden;
}

.tabs-tab-item.inactive .observance {
  color: var(--neutralblack);
}

.tabs-tab-item.active .observance {
  color: var(--primary-1);
}

@media (width >= 769px) {
  .tabs-tab-item .frame {
    margin: auto;
    position: relative;
  }
}

.tabs-tabs {
  background-color: var(--neutralwhite);
  width: 375px;
  height: 52px;
}

.tabs-tabs .overlap-group {
  height: 52px;
  position: relative;
}

.tabs-tabs .line-2 {
  object-fit: cover;
  width: 340px;
  height: 1px;
  position: absolute;
  top: 51px;
  left: 18px;
}

.first-session .text-wrapper-5 {
  text-wrap: balance !important;
  font-size: 22px !important;
  top: 82px !important;
}

.first-session .p {
  top: 165px !important;
}

.first-session .btn {
  position: absolute;
  top: 240px;
  left: 118px;
}

.first-session {
  height: 290px;
}

.card-survey {
  background-color: var(--neutralwhite);
  border-radius: 16px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  width: 340px;
  margin: 20px auto auto;
  padding: 16px 21px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.title-evaluation {
  color: var(--neutral-dark-grey, #aeaeae);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.evaluation-todo {
  color: #4282ff;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-left: 27px;
  font-weight: 500;
  display: inline-flex;
}

.question .text-wrapper-14 {
  color: #4282ff;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  font-size: 15px;
  font-weight: 500;
  display: flex;
}

.evaluation .button-evaluation {
  margin: 6px 6px 6px -7px;
}

.evaluation .group-5, .evaluation .group-6 {
  margin-right: 9px;
}

.frame-3.evaluation {
  height: auto !important;
}

.goal-confirmed {
  text-align: center;
  color: #0d6efd;
  border: 1px solid #80808029;
  border-radius: 8px;
  margin-top: 25px;
  padding: 26px;
}

.confirm-goal .heart-circle {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.frame-3.scheduled {
  position: initial !important;
  height: auto !important;
}

.qr-code {
  background: #4282ff;
  border-radius: 13px;
  width: 340px;
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 29px;
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
}

.qr-code p.text {
  text-align: center;
  color: #fff;
  font-weight: 500;
}

.qr-code p.image {
  text-align: center;
}

.qr-code p.image img {
  width: 160px;
}

.first-session .text-wrapper-5 {
  position: relative !important;
  top: 34px !important;
}

.first-session .p {
  position: relative !important;
  top: 35px !important;
}

.first-session .btn {
  position: relative !important;
  top: 38px !important;
}

.observance-mobile .default-screen {
  background-color: var(--neutralgrey);
}

.observance-mobile .default-screen .text-wrapper-5 {
  top: 100px;
  left: 65px;
}

.observance-mobile .default-screen .p {
  top: 140px;
  left: 26px;
}

.observance-mobile .default-screen .btn.btn-primary {
  position: absolute;
  top: 217px;
  left: 103px;
}

.frame-3.default {
  position: relative !important;
  top: 0 !important;
}

.evaluation .button-evaluation, .evaluation .group, .evaluation .group-6 {
  cursor: pointer;
}

.modal-content {
  margin: auto;
  width: 90% !important;
}

.welcome-apaora {
  background: #fff;
  width: 100%;
  padding-bottom: 22px;
}

.welcome-apaora .welcome {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  font-weight: 500;
}

.welcome-apaora .welcome-subtitle {
  text-align: center;
  color: #808080ab;
  margin-top: 20px;
}

.observance-mobile .default-screen .welcome-apaora .btn.btn-primary {
  top: 0;
  left: 103px;
  position: relative !important;
}

.observance-mobile .welcome-apaora .icon-arrow-arrow-down {
  margin-top: 25px;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

.define-goal {
  text-align: center;
  margin: auto;
}

.define-goal .btn.btn-primary {
  left: initial !important;
}

.btn.yes, .btn.no {
  width: 138px;
  margin-left: 5px;
  margin-right: 5px;
}

.question-no ul {
  padding-left: 0 !important;
}

.question-no ul li {
  margin-bottom: 10px !important;
}

.question-no {
  max-height: 500px;
  margin-top: 20px;
  transition: max-height .5s ease-in-out;
  overflow: hidden;
}

.question-no .other {
  border: 1px solid #ededed;
  border-radius: 5px;
  width: 294px;
  margin-bottom: 24px;
  padding: 7px;
}

.question-no .btn.send {
  width: 294px;
}

.question-no p {
  text-align: center;
}

.question .btn.btn-primary {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

.evaluation .session-missing {
  padding-left: 0;
  font-size: 14px;
}

.evaluation .session-missing li {
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.evaluation .session-missing li img {
  width: 35px;
  max-width: 100px;
  height: 35px;
  margin-right: 8px;
}

.evaluation p {
  text-align: center;
}

.evaluation .send {
  width: 250px;
}

.modal-backdrop.show {
  width: 100%;
  height: 100%;
}

.bubble-info {
  display: none;
}

@media (width <= 768px) {
  .qr-code {
    margin-bottom: 0;
  }

  .card-survey {
    margin: 15px auto auto;
  }

  .observance-mobile .session-list .information-date {
    margin-top: 11px;
    margin-bottom: 25px;
  }
}

@media (width >= 769px) {
  .tabs-tabs .overlap-group {
    display: flex;
    position: relative;
  }

  .frame-3.evaluation .card-survey, .session-list {
    margin: auto;
  }

  .observance-mobile .default-screen .text-wrapper-5 {
    top: 100px;
    left: 216px;
  }

  .observance-mobile .default-screen .p {
    top: 140px;
    left: 178px;
  }

  .observance-mobile .default-screen .btn.btn-primary {
    position: absolute;
    top: 217px;
    left: 267px;
  }

  .card-survey {
    width: 416px;
  }

  .evaluation .button-evaluation {
    margin-right: 34px !important;
  }

  .evaluation .group-5, .evaluation .group-6 {
    margin-right: 33px;
  }

  .evaluation-todo {
    margin-left: 100px;
  }

  .qr-code {
    width: 416px;
  }

  .observance-mobile .session-list .information-date {
    width: 340px !important;
    height: 36px !important;
  }
}

.icon-essential-emoji {
  background-image: url("icon-essential-emoji-sad-2.f7679913.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.icon-essential-share-wrapper {
  background-image: url("icon-essential-share-2.74494e69.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.button-bubble {
  border: 1.5px solid;
  border-color: var(--primary-1);
  border-radius: 8px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.button-bubble .icon-essential-close {
  background-image: url("icon-essential-close-circle-1.cb9d5886.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: relative;
  top: 12px;
  left: 12px;
}

.button-bubble.property-1-default {
  background-color: var(--neutralwhite);
}

.button-bubble.property-1-variant-3, .button-bubble.property-1-variant-2 {
  background-color: var(--primary-1);
}

.bubble-info {
  width: 331px;
  height: 138px;
  position: relative;
}

.bubble-info .frame-2 {
  background-color: var(--primary-1);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: fit-content;
  padding: 16px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bubble-info .text-wrapper-4 {
  color: var(--neutralwhite);
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  white-space: nowrap;
  width: 240.44px;
  margin-top: -1px;
  position: relative;
}

.bubble-info .lorem-ipsum-dolor {
  color: var(--neutralwhite);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  width: 228.86px;
  position: relative;
}

.bubble-info .button-bubble-instance {
  position: absolute !important;
  top: 90px !important;
  left: 283px !important;
}

.bubble-info .class-3 {
  background-image: url("icon-essential-close-circle-2.cb9d5886.svg") !important;
}

.bubble-info .class-4 {
  background-image: url("icon-essential-emoji-sad-5.f17b3359.svg") !important;
  position: relative !important;
  top: 12px !important;
  left: 12px !important;
}

.bubble-info .instance-node {
  position: absolute !important;
  top: 34px !important;
  left: 283px !important;
}

.bubble-info .class-5 {
  background-image: url("icon-essential-share-3.56ce879a.svg") !important;
  position: relative !important;
  top: 12px !important;
  left: 12px !important;
}

.card-ajout-d-une {
  background-color: var(--neutralwhite);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: fit-content;
  height: 247px;
  padding: 30px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card-ajout-d-une .votre-prescription-a {
  color: var(--primary-1);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 300px;
  margin-top: -1px;
  position: relative;
}

.card-ajout-d-une .div {
  min-width: 300px;
  height: 101px;
  position: relative;
}

.card-ajout-d-une .ellipse {
  object-fit: cover;
  width: 65px;
  height: 65px;
  position: absolute;
  top: 6px;
  left: 0;
}

.card-ajout-d-une .dr-jean-michel {
  color: #0000;
  letter-spacing: 0;
  width: 222px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: -1px;
  left: 40px;
}

.card-ajout-d-une .text-wrapper-2 {
  color: #2b2b2b;
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.card-ajout-d-une .text-wrapper-3 {
  color: #aeaeae;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.card-ajout-d-une .design-component-instance-node {
  top: 20px;
  width: 299px !important;
}

.my-prescription {
  cursor: pointer;
}

@media (width >= 769px) {
  .card-ajout-d-une {
    width: 416px;
  }

  .card-ajout-d-une .votre-prescription-a {
    width: 375px;
  }

  .card-ajout-d-une .div {
    min-width: 378px;
  }

  .card-ajout-d-une .design-component-instance-node {
    width: 379px !important;
  }
}

.card-programmer-une {
  background-color: var(--neutralwhite);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: fit-content;
  padding: 30px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card-programmer-une .programmez-vos-s {
  color: var(--primary-1);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  width: 299px;
  margin-top: -1px;
  position: relative;
}

.card-programmer-une .maison-hygeia {
  color: #0000;
  letter-spacing: 0;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.card-programmer-une .text-wrapper {
  color: #2b2b2b;
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.card-programmer-une .span {
  color: #aeaeae;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.card-programmer-une .button-action-instance {
  width: 299px !important;
}

@media (width >= 769px) {
  .card-programmer-une, .card-programmer-une .programmez-vos-s {
    width: 416px;
  }

  .card-programmer-une .button-action-instance {
    width: 374px !important;
  }
}

.information-date {
  width: 340px;
  height: 18px;
  position: relative;
}

.information-date .lun-mai {
  color: var(--neutraldark-grey);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 122px;
}

.information-date .line-3 {
  object-fit: cover;
  width: 90px;
  height: 1px;
  position: absolute;
  top: 9px;
  left: 2px;
}

.information-date .line-4 {
  object-fit: cover;
  width: 90px;
  height: 1px;
  position: absolute;
  top: 9px;
  left: 250px;
}

.chat-message {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--neutralgrey);
  width: 393px;
  height: 108px;
  position: relative;
}

.chat-message .lorem-ipsum-dolor {
  color: var(--primary-2);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 288px;
  position: absolute;
  top: 51px;
  left: 27px;
}

.chat-message .element {
  color: var(--neutralblack);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 199px;
  font-family: Inter-Regular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 22px;
  left: 167px;
}

.chat-message .name {
  align-items: center;
  gap: 10px;
  width: fit-content;
  display: flex;
  position: absolute;
  top: 20px;
  left: 27px;
}

.chat-message .ellipse {
  background-color: var(--primary-1);
  border-radius: 4px;
  min-width: 8px;
  height: 8px;
  position: relative;
}

.chat-message .paul-gramard {
  color: var(--neutralblack);
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-RDV {
  background-color: #fff;
  border-radius: 16px;
  width: 340px;
  height: 243px;
  position: relative;
  overflow: hidden;
}

.card-RDV .div {
  color: var(--primary-1);
  font-family: var(--display-2-font-family);
  font-size: var(--display-2-font-size);
  font-style: var(--display-2-font-style);
  font-weight: var(--display-2-font-weight);
  letter-spacing: var(--display-2-letter-spacing);
  line-height: var(--display-2-line-height);
  white-space: nowrap;
  position: absolute;
  top: 54px;
  left: 27px;
}

.card-RDV .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  white-space: nowrap;
  position: absolute;
  top: 30px;
  left: 27px;
}

.card-RDV .text-wrapper-3 {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  white-space: nowrap;
  position: absolute;
  top: 30px;
  left: 195px;
}

.card-RDV .text-wrapper-4 {
  color: var(--primary-1);
  font-family: var(--display-2-font-family);
  font-size: var(--display-2-font-size);
  font-style: var(--display-2-font-style);
  font-weight: var(--display-2-font-weight);
  letter-spacing: var(--display-2-letter-spacing);
  line-height: var(--display-2-line-height);
  white-space: nowrap;
  position: absolute;
  top: 54px;
  left: 195px;
}

.card-RDV .vector {
  width: 1px;
  height: 74px;
  position: absolute;
  top: 30px;
  left: 169px;
}

.card-RDV .tag-instance {
  position: absolute !important;
  top: 178px !important;
  left: 15px !important;
}

.card-RDV .design-component-instance-node {
  position: absolute !important;
  top: 126px !important;
  left: 169px !important;
}

.card-RDV .text-wrapper-2, .card-RDV .text-wrapper-3 {
  font-size: 19px;
}

.card-RDV .div, .card-RDV .text-wrapper-4 {
  font-size: 33px;
  font-weight: 500;
}

.centre-cardRdv {
  margin-top: 114px;
}

.centre-cardRdv .maison-hygeia {
  text-align: center;
}

.centre-cardRdv .maison-hygeia .text-wrapper {
  color: var(--neutral-black, #2c2c2c);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.centre-cardRdv .maison-hygeia .span {
  color: var(--neutral-dark-grey, #aeaeae);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.col.activity-tag {
  color: var(--primary-1);
  background: #80808014;
  border-radius: 8px;
  align-content: center;
  margin-left: 20px;
  padding: 5px;
}

.row.activity-row {
  width: 100%;
}

@media (width <= 768px) {
  .card-RDV .text-wrapper-2, .card-RDV .div {
    left: 52px;
  }

  .session-list .card-RDV {
    margin-bottom: 16px;
  }
}

@media (width >= 769px) {
  .card-RDV {
    width: 416px;
    margin: auto;
  }

  .card-RDV .text-wrapper-2, .card-RDV .div {
    left: 70px;
  }

  .card-RDV .vector {
    width: 2px;
    height: 101px;
    top: 15px;
    left: 208px;
  }

  .card-RDV .text-wrapper-3, .card-RDV .text-wrapper-4 {
    left: 255px;
  }

  .card-RDV .tag-instance {
    width: 188px;
    left: 30px !important;
  }
}

.footer-patient {
  background: #fff;
  padding: 30px;
  font-size: 12px;
}

.footer-patient .footer-cgu {
  color: #4282ff;
}

.footer-patient .support {
  text-align: center;
}

.footer-cgu {
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.footer-cgu li {
  cursor: pointer;
  margin-right: 10px;
}

@media (width >= 770px) {
  .footer.patient {
    width: 416px;
    margin: auto;
  }
}

.observance-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.observance-mobile .div-2 {
  background-color: #fff;
  border: 0;
  width: 393px;
  height: 980px;
  position: relative;
}

.observance-mobile .tabs-tabs-instance {
  width: 393px !important;
  position: absolute !important;
  top: 69px !important;
  left: 0 !important;
}

.observance-mobile .tabs-tabs-2 {
  width: 356px !important;
  left: 19px !important;
}

.observance-mobile .tabs-tabs-3 {
  left: 52px !important;
}

.observance-mobile .tabs-tabs-4 {
  width: 197px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.observance-mobile .inactive.tabs-tabs-4 {
  left: -39px !important;
}

.observance-mobile .tabs-tabs-5 {
  width: 197px !important;
}

.observance-mobile .tabs-tabs-6 {
  height: 1px !important;
  top: 23px !important;
  left: 38px !important;
}

.observance-mobile .tabs-tabs-7 {
  width: 195px !important;
  position: absolute !important;
  top: 0 !important;
  left: 190px !important;
}

.observance-mobile .active.tabs-tabs-7 {
  top: 1px !important;
  left: 211px !important;
}

.observance-mobile .tabs-tabs-8 {
  left: 57px !important;
}

.observance-mobile .active.tabs-tabs-7 .tabs-tabs-8 {
  left: 0 !important;
}

.observance-mobile .text-wrapper-10 {
  color: #000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  width: 263px;
  position: absolute;
  top: 160px;
  left: 65px;
}

.observance-mobile .text-wrapper-11 {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  text-align: center;
  width: 341px;
  position: absolute;
  top: 230px;
  left: 26px;
}

.observance-mobile .text-wrapper-5 {
  color: #000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  white-space: nowrap;
  width: 263px;
  position: absolute;
  top: 160px;
  left: 65px;
}

.observance-mobile .p {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  text-align: center;
  width: 341px;
  position: absolute;
  top: 200px;
  left: 26px;
}

.observance-mobile .icon-arrow-arrow-down {
  background-image: url("icon-arrow-arrow-down.3c0243e6.svg") !important;
  position: absolute !important;
  top: 264px !important;
  left: 184px !important;
}

.observance-mobile .frame-3 {
  background-color: var(--neutralgrey);
  border-radius: 16px 16px 0 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 393px;
  height: 850px;
  padding: 20px 26px;
  display: flex;
  position: relative;
  top: 30px;
  left: 0;
  overflow: hidden;
}

.observance-mobile .information-date-instance {
  width: unset !important;
  align-self: stretch !important;
  min-width: 341px !important;
}

.observance-mobile .information-date-2 {
  left: 123px !important;
}

.observance-mobile .information-date-3 {
  left: 251px !important;
}

.observance-mobile .header-mobile-instance {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.observance-mobile .header-mobile-2 {
  background-image: url("icon-time-calendar.a74c6303.svg") !important;
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
  top: 18px !important;
  left: 251px !important;
}

.observance-mobile .bubble-info-instance {
  position: fixed !important;
  top: 793px !important;
  left: 32px !important;
}

.observance-mobile .bubble-info-2 {
  border-color: unset !important;
  position: absolute !important;
  top: 90px !important;
  left: 283px !important;
}

.observance-mobile .bubble-info-3 {
  background-image: url("icon-essential-emoji-sad.f17b3359.svg") !important;
  position: relative !important;
  top: 12px !important;
  left: 12px !important;
}

.observance-mobile .bubble-info-4 {
  border-color: unset !important;
  position: absolute !important;
  top: 34px !important;
  left: 283px !important;
}

.observance-mobile .bubble-info-5 {
  background-image: url("icon-essential-share.56ce879a.svg") !important;
  position: relative !important;
  top: 12px !important;
  left: 12px !important;
}

.header-mobile .logout {
  position: absolute;
  top: 17px;
  left: 330px;
}

@media (width >= 375px) and (width <= 769px) {
  .observance-mobile .bubble-info-instance {
    top: 684px !important;
    left: 67px !important;
  }
}

@media (width <= 375px) {
  .observance-mobile .bubble-info-instance {
    top: 516px !important;
    left: 47px !important;
  }
}

@media (width >= 769px) and (width <= 1565px) {
  .observance-mobile .bubble-info-instance {
    top: 552px !important;
    left: 744px !important;
  }
}

@media (width >= 1565px) {
  .observance-mobile .bubble-info-instance {
    top: 741px !important;
    left: 932px !important;
  }
}

@media (width >= 769px) {
  .observance-mobile .header-mobile-instance {
    width: 100%;
  }

  .observance-mobile .header-mobile-2 {
    right: 130px;
    left: initial !important;
  }

  .header-mobile .profile-picture-instance {
    right: 70px;
    left: initial !important;
  }

  .header-mobile .class-2 {
    left: 75px !important;
  }

  .observance-mobile .tabs-tabs-instance {
    width: auto !important;
    position: relative !important;
  }

  .observance-mobile .div-2 {
    width: 750px !important;
  }

  .observance-mobile .tabs-tabs-4 {
    width: 375px !important;
    position: relative !important;
  }

  .observance-mobile .tabs-tabs-7 {
    width: 375px !important;
    position: relative !important;
    left: 0 !important;
  }

  .observance-mobile .frame-3 {
    width: 750px;
    position: relative;
    top: 30px;
  }

  .observance-mobile .information-date-instance {
    margin: auto;
  }

  .card-programmer-une, .card-ajout-d-une {
    margin: auto;
    position: relative;
  }

  .observance-mobile .text-wrapper-5 {
    left: 225px;
  }

  .observance-mobile .p {
    left: 184px;
  }

  .observance-mobile .icon-arrow-arrow-down {
    left: 339px !important;
  }

  .observance-mobile img.tabs-tabs-5, .observance-mobile .active.tabs-tabs-7 img.tabs-tabs-8 {
    display: none;
  }

  .observance-mobile .active.tabs-tabs-4 .tabs-tabs-3 {
    border-bottom: 1px solid #4282ff;
    width: 100%;
    padding-bottom: 13px;
  }

  .observance-mobile .active.tabs-tabs-7 .tabs-tabs-6 {
    border-bottom: 1px solid #4282ff;
    width: 100%;
    padding-bottom: 20px;
  }

  .observance-mobile .active.tabs-tabs-7 {
    left: 0 !important;
  }

  .header-mobile .logout {
    top: 17px;
    left: initial;
    position: absolute;
    right: 15px;
  }
}

.tabs-tab-item.inactive.tabs-tabs-7 {
  display: none;
}

.icon-essential-tick {
  background-image: url("icon-essential-tick-circle-4.df7a980f.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.steps {
  background-color: var(--neutralgrey);
  border-radius: 8px;
  align-items: flex-start;
  gap: 8px;
  width: 405px;
  padding: 17px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.steps .frame {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.steps .COORDONN-ES-PATIENT {
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.steps .renseignez-les {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: 325px;
  position: relative;
}

.steps .marie-christine {
  color: var(--neutralblack);
  white-space: nowrap;
  width: 325px;
  position: relative;
}

.steps .mariechristine {
  position: relative;
}

.steps .icon-location {
  background-image: url("icon-location-location.cc7c6828.svg");
  background-size: 100% 100%;
  min-width: 24px;
  height: 24px;
  position: relative;
}

.steps .element-rue-henry-litollf {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  width: 291.68px;
  margin-top: -1px;
  position: relative;
}

.steps .div {
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 6px 0;
  display: flex;
  position: relative;
}

.steps .element-semaines {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 325px;
  position: relative;
}

.steps .frame-2 {
  align-items: center;
  gap: 8px;
  width: fit-content;
  display: flex;
  position: relative;
}

.steps .nom-de-l-activit {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: 291.68px;
  margin-top: -1px;
  position: relative;
}

.steps .element-s {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.steps.in-progress {
  border-color: var(--primary-1);
  border-left-style: solid;
  border-left-width: 1px;
}

.steps .patient, .steps .centre-APA {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.steps .prescription {
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
}

.steps .type-patient {
  color: var(--neutralblack);
  font-family: var(--body-2-font-family);
  font-size: var(--body-2-font-size);
  font-style: var(--body-2-font-style);
  font-weight: var(--body-2-font-weight);
  letter-spacing: var(--body-2-letter-spacing);
  line-height: var(--body-2-line-height);
  white-space: nowrap;
  width: 325px;
}

.steps .type-centre-APA {
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 6px 0;
  display: flex;
}

.steps .type-prescription {
  width: 325px;
}

.steps.done .COORDONN-ES-PATIENT {
  color: var(--neutralblack);
}

.steps.in-progress .COORDONN-ES-PATIENT {
  color: var(--primary-1);
}

.steps.to-do .COORDONN-ES-PATIENT {
  color: var(--primary-2);
}

.steps.done .renseignez-les, .steps.in-progress .renseignez-les {
  color: var(--primary-1);
}

.steps.to-do .renseignez-les {
  color: var(--primary-2);
}

.steps.done {
  cursor: pointer;
}

.steps .div .btn {
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
}

.icon-arrow-arrow {
  background-image: url("icon-arrow-arrow-down-8.194c9951.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.text-field-basic {
  border: 0 solid;
  border-radius: 8px;
  width: 243px;
  position: relative;
  overflow: hidden;
}

.text-field-basic input {
  height: 52px;
}

.text-field-basic .label {
  white-space: nowrap;
  position: absolute;
  left: 8px;
}

.text-field-basic .text-wrapper {
  color: var(--neutralblack);
  position: absolute;
  top: 20px;
  left: 8px;
}

.text-field-basic .option {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 57px;
  left: 8px;
}

.text-field-basic .option-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 96px;
  left: 8px;
}

.text-field-basic .option-3 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 133px;
  left: 8px;
}

.text-field-basic .line {
  object-fit: cover;
  width: 242px;
  height: 1px;
  position: absolute;
  top: 49px;
  left: -1px;
}

.text-field-basic .img {
  object-fit: cover;
  width: 242px;
  height: 1px;
  position: absolute;
  top: 88px;
  left: -1px;
}

.text-field-basic .line-2 {
  object-fit: cover;
  width: 242px;
  height: 1px;
  position: absolute;
  top: 126px;
  left: -1px;
}

.text-field-basic .class {
  background-image: url("icon-arrow-arrow-down-5.77768b87.svg") !important;
  position: absolute !important;
  top: 13px !important;
  left: 202px !important;
}

.text-field-basic .label-2 {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 8px;
}

.text-field-basic.text-area {
  height: 124px;
}

.text-field-basic.initial, .text-field-basic.dropdown, .text-field-basic.filled {
  border-color: #ececec;
}

.text-field-basic.white {
  background-color: #fff;
}

.text-field-basic.transparent.text-area {
  border-color: #ececec;
}

.text-field-basic.filled.dropdown {
  height: 52px;
}

.text-field-basic.active.dropdown {
  height: 170px;
}

.text-field-basic.classic.active {
  border-color: #4282ff;
  height: 54px;
}

.text-field-basic.initial.dropdown {
  height: 52px;
}

.text-field-basic.classic.filled {
  height: 55px;
}

.text-field-basic.classic.initial {
  height: 52px;
}

.text-field-basic.active.white.text-area {
  border-color: #4282ff;
}

.text-field-basic.active .label {
  color: var(--primary-1);
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
}

.text-field-basic.initial .label {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  height: 24px;
  top: 12px;
}

.text-field-basic.filled .label {
  font-family: var(--label-active-font-family);
  font-size: var(--label-active-font-size);
  font-style: var(--label-active-font-style);
  font-weight: var(--label-active-font-weight);
  letter-spacing: var(--label-active-letter-spacing);
  line-height: var(--label-active-line-height);
}

.text-field-basic.active.dropdown .label {
  height: 18px;
  top: 2px;
}

.text-field-basic.filled.text-area .label {
  color: var(--primary-1);
  width: 105px;
  height: 11px;
  top: 6px;
}

.text-field-basic.classic.active .label {
  height: 18px;
  top: 2px;
}

.text-field-basic.active.text-area .label {
  width: 105px;
  height: 11px;
  top: 6px;
}

.text-field-basic.classic.filled .label, .text-field-basic.filled.dropdown .label {
  color: var(--neutraldark-grey);
  height: 18px;
  top: 2px;
}

.text-field-basic.text-area .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  width: 225px;
}

.text-field-basic.transparent .text-wrapper, .text-field-basic.dropdown .text-wrapper {
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
}

.text-field-basic.classic .text-wrapper {
  white-space: nowrap;
  height: 24px;
}

.text-field-basic.classic.white .text-wrapper {
  font-family: var(--label-initial-font-family);
  font-size: var(--label-initial-font-size);
  font-style: var(--label-initial-font-style);
  font-weight: var(--label-initial-font-weight);
  letter-spacing: var(--label-initial-letter-spacing);
  line-height: var(--label-initial-line-height);
}

.text-field-basic.active .label-2, .text-field-basic.initial .label-2 {
  color: var(--neutraldark-grey);
}

.text-field-basic.filled .label-2 {
  color: var(--neutralblack);
}

.donnes-patient {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
  display: flex;
}

.donnes-patient .div-2 {
  background-color: #fff;
  border: 1px;
  width: 1920px;
  height: auto;
  position: relative;
}

.donnes-patient .steps-instance {
  width: 356px !important;
  position: absolute !important;
  top: 104px !important;
  left: 120px !important;
}

.donnes-patient .design-component-instance-node {
  overflow: hidden !important;
}

.donnes-patient .steps-2 {
  margin-right: -49px !important;
}

.donnes-patient .steps-3 {
  width: unset !important;
  background-image: url("icon-essential-tick-circle.27973b66.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-patient .steps-4 {
  width: 356px !important;
  position: absolute !important;
  top: 207px !important;
  left: 120px !important;
}

.donnes-patient .steps-5 {
  width: 356px !important;
  position: absolute !important;
  top: 310px !important;
  left: 120px !important;
}

.donnes-patient .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 106px;
  left: 545px;
}

.donnes-patient .text-wrapper-2 {
  color: var(--primary-2, #838383);
  white-space: nowrap;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  position: absolute;
  top: 420px;
  left: 545px;
}

.donnes-patient .text-wrapper-3 {
  color: var(--primary-2, #838383);
  white-space: nowrap;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  position: absolute;
  top: 222px;
  left: 545px;
}

.donnes-patient .text-field-basic-instance {
  width: 547px !important;
  position: absolute !important;
  top: 745px !important;
  left: 545px !important;
}

.donnes-patient .text-field-basic-2 {
  background-image: url("icon-arrow-arrow-down-6.77768b87.svg") !important;
  position: absolute !important;
  top: 13px !important;
  left: 506px !important;
}

.donnes-patient .text-field-basic-3 {
  width: 547px !important;
  position: absolute !important;
  top: 266px !important;
  left: 545px !important;
}

.donnes-patient .text-field-basic-4 {
  width: 122px !important;
  position: absolute !important;
  top: 810px !important;
  left: 545px !important;
}

.donnes-patient .text-field-basic-5 {
  width: 405px !important;
  position: absolute !important;
  top: 810px !important;
  left: 687px !important;
}

.donnes-patient .text-field-basic-6 {
  width: 545px !important;
  position: absolute !important;
  top: 476px !important;
  left: 545px !important;
}

.donnes-patient .text-field-basic-7 {
  width: 547px !important;
  position: absolute !important;
  top: 612px !important;
  left: 545px !important;
}

.donnes-patient .text-field-basic-8 {
  width: 547px !important;
  position: absolute !important;
  top: 544px !important;
  left: 545px !important;
}

.donnes-patient .line-4 {
  object-fit: cover;
  width: auto;
  height: 1px;
  position: absolute;
  top: 407px;
  left: 545px;
}

.donnes-patient .bottom-buttons-instance {
  position: absolute !important;
  top: 833px !important;
  left: 545px !important;
}

.donnes-patient .bottom-buttons-2 {
  position: absolute !important;
  top: 21px !important;
  left: 1035px !important;
}

.existant-patient {
  height: 52px;
  width: 545px !important;
  position: absolute !important;
  top: 260px !important;
  left: 545px !important;
}

#code-postal {
  width: 120px !important;
}

.patient-exist-button {
  position: absolute;
  top: 333px;
  left: 545px;
}

.error-message-insert {
  color: red;
  font-weight: bold;
}

.title-patient {
  color: var(--neutral-black, #2c2c2c);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  position: absolute;
  top: 106px;
  left: 545px;
}

.followed {
  color: green;
  text-align: center;
  z-index: 1;
  visibility: visible;
  background: #fff;
  border: 1px solid green;
  border-radius: 2px;
  width: 500px;
  min-width: 250px;
  margin-left: -125px;
  padding: 16px;
  animation: .5s fadein, .5s 2.5s fadeout;
  position: fixed;
  bottom: 30px;
  left: 50%;
}

.notFollowed {
  color: red;
  text-align: center;
  z-index: 1;
  visibility: visible;
  background: #fff;
  border: 1px solid red;
  border-radius: 2px;
  width: 500px;
  min-width: 250px;
  margin-left: -125px;
  padding: 16px;
  animation: .5s fadein, .5s 2.5s fadeout;
  position: fixed;
  bottom: 30px;
  left: 50%;
}

.donnes-patient .bottom-buttons-instance .button-action-instance {
  margin-bottom: 50px;
}

.text-field-basic-9.phone {
  width: 547px !important;
  position: absolute !important;
  top: 679px !important;
  left: 545px !important;
}

.donnes-patient .steps-instance.scrolled {
  top: 38px !important;
}

.donnes-patient .steps-4.scrolled {
  top: 149px !important;
}

.donnes-patient .steps-5.scrolled {
  top: 261px !important;
}

.text-field-basic.phone {
  overflow: initial !important;
}

.searchaddress-patient {
  height: 52px;
  width: 341px !important;
  position: absolute !important;
  top: 745px !important;
  left: 545px !important;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

@media (width <= 1536px) {
  .donnes-patient .div-2 {
    width: 1495px;
  }
}

@media (width <= 767px) {
  .donnes-patient .steps-instance, .donnes-patient .steps-4, .donnes-patient .steps-5 {
    margin-bottom: 17px;
    position: relative !important;
    top: 10px !important;
    left: 41px !important;
  }

  .title-patient {
    text-align: center;
    margin-top: 40px;
    font-size: 26px;
    position: relative;
    top: 0;
    left: 0;
  }

  .donnes-patient .text-wrapper-2 {
    text-align: center;
    margin-top: 19px;
    position: relative;
    top: 0;
    left: 0;
  }

  .donnes-patient .text-wrapper-3 {
    top: 411px;
    left: 57px;
  }

  .existant-patient {
    width: 350px !important;
    top: 460px !important;
    left: 37px !important;
  }

  .donnes-patient .text-wrapper-2 {
    top: 180px;
    left: -66px;
  }

  .donnes-patient .text-field-basic-8 {
    width: auto !important;
    top: 708px !important;
    left: 43px !important;
  }

  .donnes-patient .text-field-basic-7 {
    width: auto !important;
    top: 788px !important;
    left: 44px !important;
  }

  .donnes-patient .text-field-basic-instance {
    width: auto !important;
    top: 868px !important;
    left: 45px !important;
  }

  .donnes-patient .text-field-basic-4 {
    width: auto !important;
    top: 947px !important;
    left: 45px !important;
  }

  .donnes-patient .text-field-basic-5 {
    width: auto !important;
    top: 1029px !important;
    left: 46px !important;
  }

  .donnes-patient .text-field-basic-6 {
    width: auto !important;
    top: 630px !important;
    left: 45px !important;
  }

  .donnes-patient .bottom-buttons-instance {
    width: auto !important;
    top: 1055px !important;
    left: 45px !important;
  }

  .patient-exist-button {
    position: absolute;
    top: 89px;
    left: 37px;
  }

  .donnes-patient .div-2 {
    width: auto;
    height: auto;
  }

  .logo.default {
    margin: auto;
    margin-bottom: 64px !important;
  }
}

.button-action {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-action .icon-company-logos {
  background-size: 100% 100%;
  min-width: 24px;
  height: 24px;
  position: relative;
}

.button-action .connecter-whatsapp {
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button-action.google-agenda, .button-action.outlook {
  border: 1px solid;
}

.button-action.color-transparent {
  border: 1px solid;
  border-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.color-light {
  border: 1px solid;
  gap: 10px;
  padding: 6px 17px;
}

.button-action.whatsapp, .button-action.secondary, .button-action.messenger {
  border: 1px solid;
}

.button-action.color-light.whatsapp {
  border-color: var(--neutralwhite);
}

.button-action.google-agenda.color-dark {
  border-color: #d34e25;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.primary.state-1-disabled {
  background-color: #abc7ff;
}

.button-action.color-light.primary {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.outlook {
  border-color: #2783d8;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.tertiary.color-dark {
  background-color: var(--primary-1);
  gap: 8px;
  padding: 6px 12px;
}

.button-action.whatsapp.color-dark {
  border-color: #25d366;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.state-1-initial.primary {
  background-color: var(--primary-1);
}

.button-action.color-light.messenger, .button-action.color-light.state-1-disabled {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.secondary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.google-agenda.color-light {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.primary {
  gap: 10px;
  padding: 12px 17px;
}

.button-action.state-1-initial.secondary {
  border-color: var(--primary-1);
}

.button-action.color-light.outlook, .button-action.color-light.tertiary {
  border-color: var(--neutralwhite);
}

.button-action.color-dark.messenger {
  border-color: #267cef;
  gap: 10px;
  padding: 12px 17px;
}

.button-action.secondary.color-dark.state-1-disabled {
  border-color: var(--neutraldark-grey);
}

.button-action.google-agenda .icon-company-logos {
  background-image: url("icon-company-logos-google.5b57a682.svg");
}

.button-action.outlook .icon-company-logos {
  background-image: url("icon-emails-messages-sms.dc0a3b92.svg");
}

.button-action.tertiary .icon-company-logos, .button-action.primary .icon-company-logos, .button-action.whatsapp .icon-company-logos, .button-action.secondary .icon-company-logos {
  background-image: url("icon-company-logos-whatsapp.e17555b8.svg");
}

.button-action.messenger .icon-company-logos {
  background-image: url("icon-company-logos-messenger.ccbcb7cf.svg");
}

.button-action.google-agenda .connecter-whatsapp {
  color: #d34e25;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.outlook .connecter-whatsapp {
  color: #2783d8;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.tertiary .connecter-whatsapp {
  font-family: var(--button-2-font-family);
  font-size: var(--button-2-font-size);
  font-style: var(--button-2-font-style);
  font-weight: var(--button-2-font-weight);
  letter-spacing: var(--button-2-letter-spacing);
  line-height: var(--button-2-line-height);
}

.button-action.primary .connecter-whatsapp {
  color: var(--neutralwhite);
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.whatsapp .connecter-whatsapp {
  color: #25d366;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.secondary .connecter-whatsapp {
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.messenger .connecter-whatsapp {
  color: #267cef;
  font-family: var(--button-1-font-family);
  font-size: var(--button-1-font-size);
  font-style: var(--button-1-font-style);
  font-weight: var(--button-1-font-weight);
  letter-spacing: var(--button-1-letter-spacing);
  line-height: var(--button-1-line-height);
}

.button-action.color-light.secondary .connecter-whatsapp, .button-action.tertiary.color-dark .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.tertiary.color-transparent .connecter-whatsapp, .button-action.color-transparent.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.color-light.tertiary .connecter-whatsapp {
  color: var(--neutralwhite);
}

.button-action.secondary.color-dark.state-1-disabled .connecter-whatsapp {
  color: var(--neutraldark-grey);
}

.button-action.state-1-initial.color-dark.secondary .connecter-whatsapp {
  color: var(--primary-1);
}

.button-action.state-1-initial {
  cursor: pointer;
}

.intl-tel-input {
  display: inline-block;
  position: relative;
}

.intl-tel-input * {
  box-sizing: border-box;
}

.intl-tel-input .hide {
  display: none;
}

.intl-tel-input .v-hide {
  visibility: hidden;
}

.intl-tel-input input, .intl-tel-input input[type="text"], .intl-tel-input input[type="tel"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.intl-tel-input .flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.intl-tel-input .flag-container .arrow {
  margin-left: 5px;
  font-size: 6px;
}

.intl-tel-input .flag-container .arrow.up:after {
  content: "▲";
}

.intl-tel-input .flag-container .arrow.down:after {
  content: "▼";
}

.intl-tel-input .selected-flag {
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  display: flex;
  position: relative;
}

.intl-tel-input .country-list {
  z-index: 2;
  text-align: left;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  margin: 0 0 0 -1px;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px #0003;
}

.intl-tel-input .country-list .divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.intl-tel-input .country-list.dropup {
  margin-bottom: -1px;
  bottom: 100%;
}

.intl-tel-input .country-list .flag-box {
  width: 20px;
  display: inline-block;
}

@media (width <= 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}

.intl-tel-input .country-list .country {
  padding: 5px 10px;
}

.intl-tel-input .country-list .country .dial-code {
  color: #999;
}

.intl-tel-input .country-list .country.highlight {
  background-color: #0000000d;
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
  vertical-align: middle;
}

.intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
  margin-right: 6px;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type="text"], .intl-tel-input.allow-dropdown input[type="tel"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

.intl-tel-input.allow-dropdown .flag-container {
  width: 100%;
  left: 0;
  right: auto;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 46px;
}

.intl-tel-input.allow-dropdown .flag-container:hover {
  cursor: pointer;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: #0000000d;
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
  cursor: default;
}

.intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
  background-color: #0000;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: #0000000d;
  display: table;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  vertical-align: middle;
  padding-left: 28px;
  display: table-cell;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type="text"], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type="tel"] {
  padding-left: 76px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag {
  width: 70px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type="text"], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type="tel"] {
  padding-left: 84px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag {
  width: 78px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type="text"], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type="tel"] {
  padding-left: 92px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag {
  width: 86px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type="text"], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type="tel"] {
  padding-left: 100px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag {
  width: 94px;
}

.intl-tel-input.iti-container {
  z-index: 1060;
  padding: 1px;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.intl-tel-input.iti-container:hover {
  cursor: pointer;
}

.iti-mobile .intl-tel-input.iti-container {
  position: fixed;
  inset: 30px;
}

.iti-mobile .intl-tel-input .country-list {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-height: 100%;
}

.iti-mobile .intl-tel-input .country-list .country {
  padding: 10px;
  line-height: 1.5em;
}

.iti-flag {
  width: 20px;
}

.iti-flag.be {
  width: 18px;
}

.iti-flag.ch {
  width: 15px;
}

.iti-flag.mc {
  width: 19px;
}

.iti-flag.ne {
  width: 18px;
}

.iti-flag.np {
  width: 13px;
}

.iti-flag.va {
  width: 15px;
}

@media only screen and (-webkit-device-pixel-ratio >= 2), only screen and (-moz-device-pixel-ratio >= 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (resolution >= 192dpi), only screen and (resolution >= 2x) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}

.iti-flag.ac {
  background-position: 0 0;
  height: 10px;
}

.iti-flag.ad {
  background-position: -22px 0;
  height: 14px;
}

.iti-flag.ae {
  background-position: -44px 0;
  height: 10px;
}

.iti-flag.af {
  background-position: -66px 0;
  height: 14px;
}

.iti-flag.ag {
  background-position: -88px 0;
  height: 14px;
}

.iti-flag.ai {
  background-position: -110px 0;
  height: 10px;
}

.iti-flag.al {
  background-position: -132px 0;
  height: 15px;
}

.iti-flag.am {
  background-position: -154px 0;
  height: 10px;
}

.iti-flag.ao {
  background-position: -176px 0;
  height: 14px;
}

.iti-flag.aq {
  background-position: -198px 0;
  height: 14px;
}

.iti-flag.ar {
  background-position: -220px 0;
  height: 13px;
}

.iti-flag.as {
  background-position: -242px 0;
  height: 10px;
}

.iti-flag.at {
  background-position: -264px 0;
  height: 14px;
}

.iti-flag.au {
  background-position: -286px 0;
  height: 10px;
}

.iti-flag.aw {
  background-position: -308px 0;
  height: 14px;
}

.iti-flag.ax {
  background-position: -330px 0;
  height: 13px;
}

.iti-flag.az {
  background-position: -352px 0;
  height: 10px;
}

.iti-flag.ba {
  background-position: -374px 0;
  height: 10px;
}

.iti-flag.bb {
  background-position: -396px 0;
  height: 14px;
}

.iti-flag.bd {
  background-position: -418px 0;
  height: 12px;
}

.iti-flag.be {
  background-position: -440px 0;
  height: 15px;
}

.iti-flag.bf {
  background-position: -460px 0;
  height: 14px;
}

.iti-flag.bg {
  background-position: -482px 0;
  height: 12px;
}

.iti-flag.bh {
  background-position: -504px 0;
  height: 12px;
}

.iti-flag.bi {
  background-position: -526px 0;
  height: 12px;
}

.iti-flag.bj {
  background-position: -548px 0;
  height: 14px;
}

.iti-flag.bl {
  background-position: -570px 0;
  height: 14px;
}

.iti-flag.bm {
  background-position: -592px 0;
  height: 10px;
}

.iti-flag.bn {
  background-position: -614px 0;
  height: 10px;
}

.iti-flag.bo {
  background-position: -636px 0;
  height: 14px;
}

.iti-flag.bq {
  background-position: -658px 0;
  height: 14px;
}

.iti-flag.br {
  background-position: -680px 0;
  height: 14px;
}

.iti-flag.bs {
  background-position: -702px 0;
  height: 10px;
}

.iti-flag.bt {
  background-position: -724px 0;
  height: 14px;
}

.iti-flag.bv {
  background-position: -746px 0;
  height: 15px;
}

.iti-flag.bw {
  background-position: -768px 0;
  height: 14px;
}

.iti-flag.by {
  background-position: -790px 0;
  height: 10px;
}

.iti-flag.bz {
  background-position: -812px 0;
  height: 14px;
}

.iti-flag.ca {
  background-position: -834px 0;
  height: 10px;
}

.iti-flag.cc {
  background-position: -856px 0;
  height: 10px;
}

.iti-flag.cd {
  background-position: -878px 0;
  height: 15px;
}

.iti-flag.cf {
  background-position: -900px 0;
  height: 14px;
}

.iti-flag.cg {
  background-position: -922px 0;
  height: 14px;
}

.iti-flag.ch {
  background-position: -944px 0;
  height: 15px;
}

.iti-flag.ci {
  background-position: -961px 0;
  height: 14px;
}

.iti-flag.ck {
  background-position: -983px 0;
  height: 10px;
}

.iti-flag.cl {
  background-position: -1005px 0;
  height: 14px;
}

.iti-flag.cm {
  background-position: -1027px 0;
  height: 14px;
}

.iti-flag.cn {
  background-position: -1049px 0;
  height: 14px;
}

.iti-flag.co {
  background-position: -1071px 0;
  height: 14px;
}

.iti-flag.cp {
  background-position: -1093px 0;
  height: 14px;
}

.iti-flag.cr {
  background-position: -1115px 0;
  height: 12px;
}

.iti-flag.cu {
  background-position: -1137px 0;
  height: 10px;
}

.iti-flag.cv {
  background-position: -1159px 0;
  height: 12px;
}

.iti-flag.cw {
  background-position: -1181px 0;
  height: 14px;
}

.iti-flag.cx {
  background-position: -1203px 0;
  height: 10px;
}

.iti-flag.cy {
  background-position: -1225px 0;
  height: 14px;
}

.iti-flag.cz {
  background-position: -1247px 0;
  height: 14px;
}

.iti-flag.de {
  background-position: -1269px 0;
  height: 12px;
}

.iti-flag.dg {
  background-position: -1291px 0;
  height: 10px;
}

.iti-flag.dj {
  background-position: -1313px 0;
  height: 14px;
}

.iti-flag.dk {
  background-position: -1335px 0;
  height: 15px;
}

.iti-flag.dm {
  background-position: -1357px 0;
  height: 10px;
}

.iti-flag.do {
  background-position: -1379px 0;
  height: 13px;
}

.iti-flag.dz {
  background-position: -1401px 0;
  height: 14px;
}

.iti-flag.ea {
  background-position: -1423px 0;
  height: 14px;
}

.iti-flag.ec {
  background-position: -1445px 0;
  height: 14px;
}

.iti-flag.ee {
  background-position: -1467px 0;
  height: 13px;
}

.iti-flag.eg {
  background-position: -1489px 0;
  height: 14px;
}

.iti-flag.eh {
  background-position: -1511px 0;
  height: 10px;
}

.iti-flag.er {
  background-position: -1533px 0;
  height: 10px;
}

.iti-flag.es {
  background-position: -1555px 0;
  height: 14px;
}

.iti-flag.et {
  background-position: -1577px 0;
  height: 10px;
}

.iti-flag.eu {
  background-position: -1599px 0;
  height: 14px;
}

.iti-flag.fi {
  background-position: -1621px 0;
  height: 12px;
}

.iti-flag.fj {
  background-position: -1643px 0;
  height: 10px;
}

.iti-flag.fk {
  background-position: -1665px 0;
  height: 10px;
}

.iti-flag.fm {
  background-position: -1687px 0;
  height: 11px;
}

.iti-flag.fo {
  background-position: -1709px 0;
  height: 15px;
}

.iti-flag.fr {
  background-position: -1731px 0;
  height: 14px;
}

.iti-flag.ga {
  background-position: -1753px 0;
  height: 15px;
}

.iti-flag.gb {
  background-position: -1775px 0;
  height: 10px;
}

.iti-flag.gd {
  background-position: -1797px 0;
  height: 12px;
}

.iti-flag.ge {
  background-position: -1819px 0;
  height: 14px;
}

.iti-flag.gf {
  background-position: -1841px 0;
  height: 14px;
}

.iti-flag.gg {
  background-position: -1863px 0;
  height: 14px;
}

.iti-flag.gh {
  background-position: -1885px 0;
  height: 14px;
}

.iti-flag.gi {
  background-position: -1907px 0;
  height: 10px;
}

.iti-flag.gl {
  background-position: -1929px 0;
  height: 14px;
}

.iti-flag.gm {
  background-position: -1951px 0;
  height: 14px;
}

.iti-flag.gn {
  background-position: -1973px 0;
  height: 14px;
}

.iti-flag.gp {
  background-position: -1995px 0;
  height: 14px;
}

.iti-flag.gq {
  background-position: -2017px 0;
  height: 14px;
}

.iti-flag.gr {
  background-position: -2039px 0;
  height: 14px;
}

.iti-flag.gs {
  background-position: -2061px 0;
  height: 10px;
}

.iti-flag.gt {
  background-position: -2083px 0;
  height: 13px;
}

.iti-flag.gu {
  background-position: -2105px 0;
  height: 11px;
}

.iti-flag.gw {
  background-position: -2127px 0;
  height: 10px;
}

.iti-flag.gy {
  background-position: -2149px 0;
  height: 12px;
}

.iti-flag.hk {
  background-position: -2171px 0;
  height: 14px;
}

.iti-flag.hm {
  background-position: -2193px 0;
  height: 10px;
}

.iti-flag.hn {
  background-position: -2215px 0;
  height: 10px;
}

.iti-flag.hr {
  background-position: -2237px 0;
  height: 10px;
}

.iti-flag.ht {
  background-position: -2259px 0;
  height: 12px;
}

.iti-flag.hu {
  background-position: -2281px 0;
  height: 10px;
}

.iti-flag.ic {
  background-position: -2303px 0;
  height: 14px;
}

.iti-flag.id {
  background-position: -2325px 0;
  height: 14px;
}

.iti-flag.ie {
  background-position: -2347px 0;
  height: 10px;
}

.iti-flag.il {
  background-position: -2369px 0;
  height: 15px;
}

.iti-flag.im {
  background-position: -2391px 0;
  height: 10px;
}

.iti-flag.in {
  background-position: -2413px 0;
  height: 14px;
}

.iti-flag.io {
  background-position: -2435px 0;
  height: 10px;
}

.iti-flag.iq {
  background-position: -2457px 0;
  height: 14px;
}

.iti-flag.ir {
  background-position: -2479px 0;
  height: 12px;
}

.iti-flag.is {
  background-position: -2501px 0;
  height: 15px;
}

.iti-flag.it {
  background-position: -2523px 0;
  height: 14px;
}

.iti-flag.je {
  background-position: -2545px 0;
  height: 12px;
}

.iti-flag.jm {
  background-position: -2567px 0;
  height: 10px;
}

.iti-flag.jo {
  background-position: -2589px 0;
  height: 10px;
}

.iti-flag.jp {
  background-position: -2611px 0;
  height: 14px;
}

.iti-flag.ke {
  background-position: -2633px 0;
  height: 14px;
}

.iti-flag.kg {
  background-position: -2655px 0;
  height: 12px;
}

.iti-flag.kh {
  background-position: -2677px 0;
  height: 13px;
}

.iti-flag.ki {
  background-position: -2699px 0;
  height: 10px;
}

.iti-flag.km {
  background-position: -2721px 0;
  height: 12px;
}

.iti-flag.kn {
  background-position: -2743px 0;
  height: 14px;
}

.iti-flag.kp {
  background-position: -2765px 0;
  height: 10px;
}

.iti-flag.kr {
  background-position: -2787px 0;
  height: 14px;
}

.iti-flag.kw {
  background-position: -2809px 0;
  height: 10px;
}

.iti-flag.ky {
  background-position: -2831px 0;
  height: 10px;
}

.iti-flag.kz {
  background-position: -2853px 0;
  height: 10px;
}

.iti-flag.la {
  background-position: -2875px 0;
  height: 14px;
}

.iti-flag.lb {
  background-position: -2897px 0;
  height: 14px;
}

.iti-flag.lc {
  background-position: -2919px 0;
  height: 10px;
}

.iti-flag.li {
  background-position: -2941px 0;
  height: 12px;
}

.iti-flag.lk {
  background-position: -2963px 0;
  height: 10px;
}

.iti-flag.lr {
  background-position: -2985px 0;
  height: 11px;
}

.iti-flag.ls {
  background-position: -3007px 0;
  height: 14px;
}

.iti-flag.lt {
  background-position: -3029px 0;
  height: 12px;
}

.iti-flag.lu {
  background-position: -3051px 0;
  height: 12px;
}

.iti-flag.lv {
  background-position: -3073px 0;
  height: 10px;
}

.iti-flag.ly {
  background-position: -3095px 0;
  height: 10px;
}

.iti-flag.ma {
  background-position: -3117px 0;
  height: 14px;
}

.iti-flag.mc {
  background-position: -3139px 0;
  height: 15px;
}

.iti-flag.md {
  background-position: -3160px 0;
  height: 10px;
}

.iti-flag.me {
  background-position: -3182px 0;
  height: 10px;
}

.iti-flag.mf {
  background-position: -3204px 0;
  height: 14px;
}

.iti-flag.mg {
  background-position: -3226px 0;
  height: 14px;
}

.iti-flag.mh {
  background-position: -3248px 0;
  height: 11px;
}

.iti-flag.mk {
  background-position: -3270px 0;
  height: 10px;
}

.iti-flag.ml {
  background-position: -3292px 0;
  height: 14px;
}

.iti-flag.mm {
  background-position: -3314px 0;
  height: 14px;
}

.iti-flag.mn {
  background-position: -3336px 0;
  height: 10px;
}

.iti-flag.mo {
  background-position: -3358px 0;
  height: 14px;
}

.iti-flag.mp {
  background-position: -3380px 0;
  height: 10px;
}

.iti-flag.mq {
  background-position: -3402px 0;
  height: 14px;
}

.iti-flag.mr {
  background-position: -3424px 0;
  height: 14px;
}

.iti-flag.ms {
  background-position: -3446px 0;
  height: 10px;
}

.iti-flag.mt {
  background-position: -3468px 0;
  height: 14px;
}

.iti-flag.mu {
  background-position: -3490px 0;
  height: 14px;
}

.iti-flag.mv {
  background-position: -3512px 0;
  height: 14px;
}

.iti-flag.mw {
  background-position: -3534px 0;
  height: 14px;
}

.iti-flag.mx {
  background-position: -3556px 0;
  height: 12px;
}

.iti-flag.my {
  background-position: -3578px 0;
  height: 10px;
}

.iti-flag.mz {
  background-position: -3600px 0;
  height: 14px;
}

.iti-flag.na {
  background-position: -3622px 0;
  height: 14px;
}

.iti-flag.nc {
  background-position: -3644px 0;
  height: 10px;
}

.iti-flag.ne {
  background-position: -3666px 0;
  height: 15px;
}

.iti-flag.nf {
  background-position: -3686px 0;
  height: 10px;
}

.iti-flag.ng {
  background-position: -3708px 0;
  height: 10px;
}

.iti-flag.ni {
  background-position: -3730px 0;
  height: 12px;
}

.iti-flag.nl {
  background-position: -3752px 0;
  height: 14px;
}

.iti-flag.no {
  background-position: -3774px 0;
  height: 15px;
}

.iti-flag.np {
  background-position: -3796px 0;
  height: 15px;
}

.iti-flag.nr {
  background-position: -3811px 0;
  height: 10px;
}

.iti-flag.nu {
  background-position: -3833px 0;
  height: 10px;
}

.iti-flag.nz {
  background-position: -3855px 0;
  height: 10px;
}

.iti-flag.om {
  background-position: -3877px 0;
  height: 10px;
}

.iti-flag.pa {
  background-position: -3899px 0;
  height: 14px;
}

.iti-flag.pe {
  background-position: -3921px 0;
  height: 14px;
}

.iti-flag.pf {
  background-position: -3943px 0;
  height: 14px;
}

.iti-flag.pg {
  background-position: -3965px 0;
  height: 15px;
}

.iti-flag.ph {
  background-position: -3987px 0;
  height: 10px;
}

.iti-flag.pk {
  background-position: -4009px 0;
  height: 14px;
}

.iti-flag.pl {
  background-position: -4031px 0;
  height: 13px;
}

.iti-flag.pm {
  background-position: -4053px 0;
  height: 14px;
}

.iti-flag.pn {
  background-position: -4075px 0;
  height: 10px;
}

.iti-flag.pr {
  background-position: -4097px 0;
  height: 14px;
}

.iti-flag.ps {
  background-position: -4119px 0;
  height: 10px;
}

.iti-flag.pt {
  background-position: -4141px 0;
  height: 14px;
}

.iti-flag.pw {
  background-position: -4163px 0;
  height: 13px;
}

.iti-flag.py {
  background-position: -4185px 0;
  height: 11px;
}

.iti-flag.qa {
  background-position: -4207px 0;
  height: 8px;
}

.iti-flag.re {
  background-position: -4229px 0;
  height: 14px;
}

.iti-flag.ro {
  background-position: -4251px 0;
  height: 14px;
}

.iti-flag.rs {
  background-position: -4273px 0;
  height: 14px;
}

.iti-flag.ru {
  background-position: -4295px 0;
  height: 14px;
}

.iti-flag.rw {
  background-position: -4317px 0;
  height: 14px;
}

.iti-flag.sa {
  background-position: -4339px 0;
  height: 14px;
}

.iti-flag.sb {
  background-position: -4361px 0;
  height: 10px;
}

.iti-flag.sc {
  background-position: -4383px 0;
  height: 10px;
}

.iti-flag.sd {
  background-position: -4405px 0;
  height: 10px;
}

.iti-flag.se {
  background-position: -4427px 0;
  height: 13px;
}

.iti-flag.sg {
  background-position: -4449px 0;
  height: 14px;
}

.iti-flag.sh {
  background-position: -4471px 0;
  height: 10px;
}

.iti-flag.si {
  background-position: -4493px 0;
  height: 10px;
}

.iti-flag.sj {
  background-position: -4515px 0;
  height: 15px;
}

.iti-flag.sk {
  background-position: -4537px 0;
  height: 14px;
}

.iti-flag.sl {
  background-position: -4559px 0;
  height: 14px;
}

.iti-flag.sm {
  background-position: -4581px 0;
  height: 15px;
}

.iti-flag.sn {
  background-position: -4603px 0;
  height: 14px;
}

.iti-flag.so {
  background-position: -4625px 0;
  height: 14px;
}

.iti-flag.sr {
  background-position: -4647px 0;
  height: 14px;
}

.iti-flag.ss {
  background-position: -4669px 0;
  height: 10px;
}

.iti-flag.st {
  background-position: -4691px 0;
  height: 10px;
}

.iti-flag.sv {
  background-position: -4713px 0;
  height: 12px;
}

.iti-flag.sx {
  background-position: -4735px 0;
  height: 14px;
}

.iti-flag.sy {
  background-position: -4757px 0;
  height: 14px;
}

.iti-flag.sz {
  background-position: -4779px 0;
  height: 14px;
}

.iti-flag.ta {
  background-position: -4801px 0;
  height: 10px;
}

.iti-flag.tc {
  background-position: -4823px 0;
  height: 10px;
}

.iti-flag.td {
  background-position: -4845px 0;
  height: 14px;
}

.iti-flag.tf {
  background-position: -4867px 0;
  height: 14px;
}

.iti-flag.tg {
  background-position: -4889px 0;
  height: 13px;
}

.iti-flag.th {
  background-position: -4911px 0;
  height: 14px;
}

.iti-flag.tj {
  background-position: -4933px 0;
  height: 10px;
}

.iti-flag.tk {
  background-position: -4955px 0;
  height: 10px;
}

.iti-flag.tl {
  background-position: -4977px 0;
  height: 10px;
}

.iti-flag.tm {
  background-position: -4999px 0;
  height: 14px;
}

.iti-flag.tn {
  background-position: -5021px 0;
  height: 14px;
}

.iti-flag.to {
  background-position: -5043px 0;
  height: 10px;
}

.iti-flag.tr {
  background-position: -5065px 0;
  height: 14px;
}

.iti-flag.tt {
  background-position: -5087px 0;
  height: 12px;
}

.iti-flag.tv {
  background-position: -5109px 0;
  height: 10px;
}

.iti-flag.tw {
  background-position: -5131px 0;
  height: 14px;
}

.iti-flag.tz {
  background-position: -5153px 0;
  height: 14px;
}

.iti-flag.ua {
  background-position: -5175px 0;
  height: 14px;
}

.iti-flag.ug {
  background-position: -5197px 0;
  height: 14px;
}

.iti-flag.um {
  background-position: -5219px 0;
  height: 11px;
}

.iti-flag.us {
  background-position: -5241px 0;
  height: 11px;
}

.iti-flag.uy {
  background-position: -5263px 0;
  height: 14px;
}

.iti-flag.uz {
  background-position: -5285px 0;
  height: 10px;
}

.iti-flag.va {
  background-position: -5307px 0;
  height: 15px;
}

.iti-flag.vc {
  background-position: -5324px 0;
  height: 14px;
}

.iti-flag.ve {
  background-position: -5346px 0;
  height: 14px;
}

.iti-flag.vg {
  background-position: -5368px 0;
  height: 10px;
}

.iti-flag.vi {
  background-position: -5390px 0;
  height: 14px;
}

.iti-flag.vn {
  background-position: -5412px 0;
  height: 14px;
}

.iti-flag.vu {
  background-position: -5434px 0;
  height: 12px;
}

.iti-flag.wf {
  background-position: -5456px 0;
  height: 14px;
}

.iti-flag.ws {
  background-position: -5478px 0;
  height: 10px;
}

.iti-flag.xk {
  background-position: -5500px 0;
  height: 15px;
}

.iti-flag.ye {
  background-position: -5522px 0;
  height: 14px;
}

.iti-flag.yt {
  background-position: -5544px 0;
  height: 14px;
}

.iti-flag.za {
  background-position: -5566px 0;
  height: 14px;
}

.iti-flag.zm {
  background-position: -5588px 0;
  height: 14px;
}

.iti-flag.zw {
  background-position: -5610px 0;
  height: 10px;
}

.iti-flag {
  background-color: #dbdbdb;
  background-image: url("flags.2abd8442.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  width: 20px;
  height: 15px;
  box-shadow: 0 0 1px #888;
}

@media only screen and (-webkit-device-pixel-ratio >= 2), only screen and (-moz-device-pixel-ratio >= 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (resolution >= 192dpi), only screen and (resolution >= 2x) {
  .iti-flag {
    background-image: url("flags@2x.ce79d567.png");
  }
}

.iti-flag.np {
  background-color: #0000;
}

.centre {
  background-color: var(--neutralgrey);
  border-radius: 8px;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  padding: 25px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.centre .frame-3 {
  min-width: 1199.84px;
  height: 64.49px;
  position: relative;
}

.centre .button-action-instance {
  position: absolute !important;
  top: 0 !important;
  left: 1041px !important;
}

.centre .rectangle {
  background-color: var(--neutralmedium-grey);
  border-radius: 4px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
}

.centre .group {
  width: 418px;
  height: 62px;
  position: absolute;
  top: 1px;
  left: 95px;
}

.centre .nom-du-centre {
  color: #000;
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.centre .frame-4 {
  align-items: flex-start;
  gap: 21px;
  width: fit-content;
  display: flex;
  position: absolute;
  top: 38px;
  left: 1px;
}

.centre .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.centre .line {
  object-fit: cover;
  min-width: 1px;
  height: 24.43px;
  position: relative;
}

.centre .frame-wrapper {
  min-width: 1197px;
  height: 32px;
  position: relative;
  overflow: hidden;
}

.centre .frame-5 {
  align-items: center;
  gap: 8px;
  width: fit-content;
  display: flex;
  position: relative;
  left: 96px;
}

.centre.default {
  justify-content: center;
  align-items: center;
}

.centre.variant-2 {
  border: 2px solid;
  border-color: var(--primary-1);
  align-items: flex-start;
}

.centre.variant-2 .nom-du-centre {
  height: 33px;
}

.title-center {
  margin-top: 96px;
  margin-bottom: 15px;
  font-size: 34px;
}

.proximity {
  color: gray;
  font-weight: 500;
}

.container.centre {
  cursor: pointer;
  background: #80808014;
  border-radius: 13px;
  width: 85%;
  height: auto;
  margin-left: 0;
  padding: 18px;
}

.container.centre .col-md-4 {
  text-align: right;
}

.activity-name .btn-light {
  color: #4282ff;
  background: #fff;
  width: 200px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.centre.active .row .col-md-4 {
  justify-content: flex-end;
  height: auto;
  display: flex;
}

.centre.active .row .col-md-4 .button-centre {
  margin: auto;
}

.centre.false .row .col-md-4 {
  justify-content: flex-end;
  height: auto;
  display: flex;
}

.centre .btn-outline-primary.first {
  margin: auto 15px auto auto !important;
}

@media (width <= 1536px) {
  .proximity, .title-center, .container.centre {
    margin-left: 32px;
  }
}

@media (width >= 1537px) {
  .donnes-prescription .col-md-1, .donnes-centre .col-md-1 {
    width: 4%;
  }
}

.donnes-centre {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
  display: flex;
}

.donnes-centre .div-2 {
  background-color: #fff;
  border: 1px;
  width: 1920px;
  height: 1080px;
  position: relative;
}

.donnes-centre .steps-instance {
  width: 356px !important;
  position: absolute !important;
  top: 104px !important;
  left: 120px !important;
}

.donnes-centre .design-component-instance-node {
  overflow: hidden !important;
}

.donnes-centre .steps-2 {
  margin-right: -49px !important;
}

.donnes-centre .steps-3 {
  margin-right: -47.68px !important;
}

.donnes-centre .steps-4 {
  width: unset !important;
  background-image: url("icon-location-location.cc7c6828.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-centre .steps-5 {
  width: unset !important;
  background-image: url("icon-essential-tick-circle-2.fc7db8aa.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-centre .steps-6 {
  width: 356px !important;
  position: absolute !important;
  top: 298px !important;
  left: 120px !important;
}

.donnes-centre .steps-7 {
  width: unset !important;
  background-image: url("icon-essential-tick-circle.27973b66.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-centre .steps-8 {
  width: 356px !important;
  position: absolute !important;
  top: 401px !important;
  left: 120px !important;
}

.donnes-centre .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 106px;
  left: 545px;
}

.donnes-centre .p {
  color: var(--neutraldark-grey);
  font-family: var(--body-2-bold-font-family);
  font-size: var(--body-2-bold-font-size);
  font-style: var(--body-2-bold-font-style);
  font-weight: var(--body-2-bold-font-weight);
  letter-spacing: var(--body-2-bold-letter-spacing);
  line-height: var(--body-2-bold-line-height);
  white-space: nowrap;
  height: 21px;
  position: absolute;
  top: 166px;
  left: 545px;
}

.donnes-centre .centre-instance {
  position: absolute !important;
  top: 214px !important;
  left: 545px !important;
}

.donnes-centre .centre-2 {
  position: absolute !important;
  top: 390px !important;
  left: 545px !important;
}

.donnes-centre .centre-3 {
  position: absolute !important;
  top: 567px !important;
  left: 545px !important;
}

.donnes-centre .bottom-buttons-instance {
  position: absolute !important;
  top: 833px !important;
  left: 545px !important;
}

.donnes-centre .bottom-buttons-2 {
  position: absolute !important;
  top: 21px !important;
  left: 1035px !important;
}

.centre.centre-instance.active {
  cursor: pointer;
  border: 1px solid #4282ffad;
}

.centre.centre-instance {
  cursor: pointer;
}

.centre-button {
  position: absolute;
  top: 423px;
  right: 105px;
}

.container.centre.active {
  border: 1px solid #0d6efd;
}

.container.centre {
  margin-bottom: 20px;
}

.bottom-buttons.centre-button-continue {
  margin-right: 167px;
}

.donnes-centre .steps.in-progress.steps-6 {
  top: 325px !important;
}

.donnes-centre .steps-8 {
  top: 432px !important;
}

.donnes-centre .title-center {
  color: var(--neutral-black, #2c2c2c);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.donnes-centre .steps-instance.scrolled {
  top: 38px !important;
}

.donnes-centre .in-progress.steps-6.scrolled {
  top: 261px !important;
}

.donnes-centre .steps-8.scrolled {
  top: 373px !important;
}

@media (width >= 1537px) {
  .bottom-buttons.centre-button-continue {
    margin-left: 9px;
    margin-right: 190px;
  }

  .donnes-centre .centre-button-continue .col-md-12 {
    margin-left: 25px;
  }
}

@media (width <= 1536px) {
  .donnes-centre .div-2 {
    width: 1495px;
  }

  .bottom-buttons.centre-button-continue {
    margin-left: 35px;
    margin-right: 121px;
  }

  .donnes-centre .centre-button-continue .col-md-12 {
    margin-left: 25px;
  }
}

@media (width <= 767px) {
  .donnes-centre .div-2 {
    width: auto;
    height: auto;
  }
}

@media (width >= 950px) and (width <= 1400px) {
  .col-md-8.choice-center {
    margin-left: 489px !important;
  }
}

.donnes-prescription {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: -100px;
  display: flex;
}

.donnes-prescription .div-2 {
  background-color: #fff;
  border: 1px;
  width: 1920px;
  height: auto;
  position: relative;
}

.donnes-prescription .design-component-instance-node {
  position: absolute !important;
  top: 1644px !important;
  left: 545px !important;
}

.donnes-prescription .bottom-buttons-instance {
  position: absolute !important;
  top: 1799px !important;
  left: 545px !important;
}

.donnes-prescription .bottom-buttons-2 {
  left: 746px !important;
}

.donnes-prescription .text-field-basic-instance {
  width: 547px !important;
  position: absolute !important;
  top: 452px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-2 {
  background-image: url("icon-arrow-arrow-down-1.77768b87.svg") !important;
  position: absolute !important;
  top: 13px !important;
  left: 506px !important;
}

.donnes-prescription .text-field-basic-3 {
  left: 20px !important;
}

.donnes-prescription .text-field-basic-4 {
  width: 547px !important;
  position: absolute !important;
  top: 644px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-5 {
  width: 547px !important;
  position: absolute !important;
  top: 883px !important;
  left: 545px !important;
}

.donnes-prescription .text-wrapper-2 {
  color: var(--primary-1);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  text-align: right;
  white-space: nowrap;
  height: 18px;
  position: absolute;
  top: 1247px;
  left: 1100px;
}

.donnes-prescription .text-wrapper-3 {
  color: var(--primary-3);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  text-align: right;
  white-space: nowrap;
  height: 18px;
  position: absolute;
  top: 1247px;
  left: 1000px;
}

.donnes-prescription .text-wrapper-4 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 537px;
  left: 545px;
}

.donnes-prescription .text-wrapper-5 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 786px;
  left: 545px;
}

.donnes-prescription .s-ries-facultatif {
  color: var(--primary-2);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 1032px;
  left: 545px;
}

.donnes-prescription .span {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
}

.donnes-prescription .text-wrapper-6 {
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.donnes-prescription .text-wrapper-7 {
  color: var(--primary-2);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 1032px;
  left: 687px;
}

.donnes-prescription .text-wrapper-8 {
  color: var(--primary-2);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  position: absolute;
  top: 1187px;
  left: 545px;
}

.donnes-prescription .tag-selection-instance {
  position: absolute !important;
  top: 577px !important;
  left: 545px !important;
}

.donnes-prescription .tag-selection-2 {
  position: absolute !important;
  top: 820px !important;
  left: 545px !important;
}

.donnes-prescription .tag-selection-3 {
  position: absolute !important;
  top: 577px !important;
  left: 684px !important;
}

.donnes-prescription .tag-selection-4 {
  position: absolute !important;
  top: 820px !important;
  left: 649px !important;
}

.donnes-prescription .tag-selection-5 {
  position: absolute !important;
  top: 577px !important;
  left: 807px !important;
}

.donnes-prescription .tag-selection-6 {
  position: absolute !important;
  top: 820px !important;
  left: 775px !important;
}

.donnes-prescription .p {
  color: #000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  position: absolute;
  top: 1240px;
  left: 545px;
}

.donnes-prescription .overlap-group {
  border: 1px solid #4282ff;
  border-radius: 24px;
  width: 58px;
  height: 32px;
  position: absolute;
  top: 1240px;
  left: 1034px;
}

.donnes-prescription .ellipse {
  background-color: var(--primary-1);
  border-radius: 12px;
  width: 24px;
  height: 24px;
  position: relative;
  top: 3px;
  left: 28px;
}

.donnes-prescription .text-field-basic-6 {
  width: 405px !important;
  position: absolute !important;
  top: 1287px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-7 {
  width: 405px !important;
  position: absolute !important;
  top: 1353px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-8 {
  width: 405px !important;
  position: absolute !important;
  top: 1416px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-9 {
  width: 405px !important;
  position: absolute !important;
  top: 1484px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-basic-10 {
  width: 405px !important;
  position: absolute !important;
  top: 1549px !important;
  left: 545px !important;
}

.donnes-prescription .h-1 {
  color: var(--neutralblack);
  font-family: var(--heading-1-desktop-font-family);
  font-size: var(--heading-1-desktop-font-size);
  font-style: var(--heading-1-desktop-font-style);
  font-weight: var(--heading-1-desktop-font-weight);
  letter-spacing: var(--heading-1-desktop-letter-spacing);
  line-height: var(--heading-1-desktop-line-height);
  white-space: nowrap;
  font-size: 30px;
  position: absolute;
  top: 106px;
  left: 545px;
}

.donnes-prescription .text-wrapper-9 {
  color: var(--neutralblack);
  font-family: var(--heading-2-desktop-font-family);
  font-size: var(--heading-2-desktop-font-size);
  font-style: var(--heading-2-desktop-font-style);
  font-weight: var(--heading-2-desktop-font-weight);
  letter-spacing: var(--heading-2-desktop-letter-spacing);
  line-height: var(--heading-2-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 335px;
  left: 545px;
}

.donnes-prescription .text-wrapper-10 {
  color: var(--neutralblack);
  font-family: var(--heading-2-desktop-font-family);
  font-size: var(--heading-2-desktop-font-size);
  font-style: var(--heading-2-desktop-font-style);
  font-weight: var(--heading-2-desktop-font-weight);
  letter-spacing: var(--heading-2-desktop-letter-spacing);
  line-height: var(--heading-2-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 186px;
  left: 545px;
}

.donnes-prescription .text-wrapper-11 {
  color: var(--primary-2);
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 396px;
  left: 545px;
}

.donnes-prescription .text-wrapper-12 {
  color: var(--primary-2);
  font-family: var(--heading-3-desktop-font-family);
  font-size: var(--heading-3-desktop-font-size);
  font-style: var(--heading-3-desktop-font-style);
  font-weight: var(--heading-3-desktop-font-weight);
  letter-spacing: var(--heading-3-desktop-letter-spacing);
  line-height: var(--heading-3-desktop-line-height);
  white-space: nowrap;
  position: absolute;
  top: 396px;
  left: 1112px;
}

.donnes-prescription .steps-instance {
  width: 356px !important;
  position: absolute !important;
  top: 104px !important;
  left: 120px !important;
}

.donnes-prescription .steps-2 {
  overflow: hidden !important;
}

.donnes-prescription .steps-3 {
  margin-right: -49px !important;
}

.donnes-prescription .steps-4 {
  margin-right: -47.68px !important;
}

.donnes-prescription .steps-5 {
  width: unset !important;
  background-image: url("icon-location-location-4.cc7c6828.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-prescription .steps-6 {
  width: unset !important;
  background-image: url("icon-essential-tick-circle-12.fc7db8aa.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-prescription .steps-7 {
  width: 356px !important;
  position: absolute !important;
  top: 298px !important;
  left: 120px !important;
}

.donnes-prescription .steps-8 {
  margin-right: -11px !important;
}

.donnes-prescription .steps-9 {
  width: 356px !important;
  position: absolute !important;
  top: 518px !important;
  left: 120px !important;
}

.donnes-prescription .steps-10 {
  width: unset !important;
  background-image: url("icon-essential-tick-circle-11.27973b66.svg") !important;
  min-width: 24px !important;
  position: relative !important;
}

.donnes-prescription .text-wrapper-13 {
  color: #000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  position: absolute;
  top: 454px;
  left: 1187px;
}

.donnes-prescription .text-field-number-caps {
  position: absolute !important;
  top: 250px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-number-instance {
  position: absolute !important;
  top: 448px !important;
  left: 1112px !important;
}

.donnes-prescription .text-wrapper-14 {
  color: #000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  position: absolute;
  top: 255px;
  left: 620px;
}

.donnes-prescription .text-field-number-caps-instance {
  position: absolute !important;
  top: 1073px !important;
  left: 545px !important;
}

.donnes-prescription .text-field-number-2 {
  position: absolute !important;
  top: 1073px !important;
  left: 687px !important;
}

.donnes-prescription .text-wrapper-15 {
  color: #000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  position: absolute;
  top: 1090px;
  left: 610px;
}

.donnes-prescription .text-wrapper-16 {
  color: #000;
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  white-space: nowrap;
  position: absolute;
  top: 1090px;
  left: 752px;
}

.bottom-buttons {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 50px;
}

.bottom-buttons button {
  margin: 15px;
}

.donnes-prescription .steps.done.steps-7 {
  top: 325px !important;
}

.donnes-prescription .in-progress.steps-9 {
  top: 571px !important;
}

.exercise-type .btn, .row.intensity .btn {
  border-radius: 20px;
}

.donnes-prescription .title {
  color: var(--neutral-black, #2c2c2c);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.donnes-prescription .subtitle {
  color: var(--neutral-black, #2c2c2c);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
}

.donnes-prescription .steps-instance.scrolled {
  top: 38px !important;
}

.donnes-prescription .steps.done.steps-7.scrolled {
  top: 265px !important;
}

.donnes-prescription .in-progress.steps-9.scrolled {
  top: 516px !important;
}

.react-confirm-alert-button-group > .not-cancelled-prescription {
  color: #0d6efd;
  border: 1px solid #0d6efd;
  background: #fff !important;
}

.react-confirm-alert-body {
  width: 440px;
}

.react-confirm-alert-body h1 {
  text-align: center;
}

@media (width <= 1536px) {
  .donnes-prescription .div-2 {
    width: 1495px;
  }

  .donnes-prescription .form-outline.frequency, .donnes-prescription .subtitle, .donnes-prescription .title, .donnes-prescription .add-exercise {
    margin-left: 32px;
  }

  .donnes-prescription .formulaire-prescription .subtitle {
    margin-left: 0;
  }
}

@media (width >= 950px) and (width <= 1400px) {
  .col-md-8.define-prescription {
    margin-left: 489px !important;
  }

  .donnes-prescription .steps-7.scrolled, .donnes-prescription .steps-9.scrolled, .donnes-prescription .steps-instance.scrolled {
    left: 60px !important;
  }
}

.title {
  margin-top: 96px;
  margin-bottom: 50px;
  font-size: 34px;
}

#typeNumber {
  width: 70px;
  font-size: 20px;
  font-weight: bold;
}

.form-outline {
  display: flex;
}

.form-outline label {
  margin-top: 17px;
  margin-left: 20px;
}

.subtitle {
  margin-top: 50px;
  margin-bottom: 20px;
}

span {
  color: gray;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 37px !important;
}

.exercise-type, .intensity {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 5px;
  display: flex;
}

.exercise-type .first, .exercise-type .second, .intensity button {
  width: 120px;
  margin-right: 15px;
}

.exercise-type .third {
  width: 220px;
}

textarea {
  height: 124px;
  margin-bottom: 50px;
  width: 481px !important;
}

.series {
  margin-bottom: 50px;
}

.form-switch {
  margin-left: 15px;
  margin-right: 10px;
}

.form-switch input {
  height: 20px;
}

.precaution {
  display: flex;
}

#typeText {
  width: 500px;
  margin-bottom: 20px;
}

.activity, .week {
  margin-top: 20px;
  margin-bottom: 30px;
}

.precaution-input {
  display: none;
}

.precaution-input.active {
  display: block;
}

.exercise-details.row {
  margin-bottom: 10px;
}

.check-exercise-details {
  margin-bottom: 40px;
}

.check-exercise-details strong {
  margin-left: 10px;
}

@media (width <= 1536px) {
  .formulaire-prescription {
    margin-left: 32px;
  }
}

.login-button {
  color: #0d6efd !important;
  background: #fff !important;
  border: 1px solid #0d6efd !important;
}

.login-button:hover {
  border: 1px solid var(--bs-btn-hover-bg);
  color: var(--bs-btn-hover-bg);
}

@media (width <= 768px) {
  .logo-auth {
    margin-left: 41px;
  }

  .col.forgot-password, .col.justify-content-center, .text-center {
    font-size: 13px;
  }
}

@font-face {
  font-family: biographyregular;
  src: local(biographyregular), url("biography-webfont.a706045a.woff2") format("woff2"), url("biography-webfont.a5b7205b.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.signature-canvas {
  border: 1px solid #80808042;
  border-radius: 10px;
  margin-top: 42px;
  position: absolute;
}

#myCanvas {
  border-radius: 12px;
  width: 506px;
  margin-top: 33px;
  padding-left: 34px;
  font-family: biographyregular;
  font-size: 46px;
  border: 1px solid #80808042 !important;
}

.note-signature {
  width: 530px;
  margin-top: 30px;
  font-family: Inter;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
}

.clear-signature {
  color: #61a1fe;
  cursor: pointer;
  text-align: right;
  z-index: 100;
  width: 483px;
  margin-top: 52px;
  position: absolute;
}

.sign {
  color: #61a1fe;
  cursor: pointer;
  text-align: right;
  z-index: 100;
  width: 410px;
  margin-top: 52px;
  position: absolute;
}

.steps.scrolled {
  position: fixed !important;
}

@media (width <= 1536px) {
  .note-signature, .donnes-prescription #editor, #myCanvas, .check {
    margin-left: 32px;
  }
}

@media (width >= 950px) and (width <= 1400px) {
  .col-md-8.finalisation-inscription-text {
    margin-left: 489px !important;
  }
}

.prescription {
  border: 1px solid #80808021;
  border-radius: 14px;
  width: 750px;
  padding: 33px;
}

.prescription p {
  color: gray;
}

.check {
  border: 1px solid #80808021;
  border-radius: 14px;
  width: 750px;
  margin-top: 34px;
  padding: 10px;
  display: flex;
}

.check strong {
  margin-left: 15px;
}

#editor {
  width: 500px;
}

.edit-save {
  color: #0d6efd;
  cursor: pointer;
  margin-top: 5px;
  font-weight: 500;
  position: absolute;
  right: 550px;
}

#prescription p:first-child {
  margin-top: 25px;
}

@media (width <= 1548px) {
  .edit-save {
    color: #0d6efd;
    cursor: pointer;
    margin-top: 18px;
    font-weight: 500;
    text-decoration: underline;
    position: absolute;
    right: 247px;
  }
}

@media (width >= 1549px) {
  .edit-save {
    margin-top: 20px;
    text-decoration: underline;
    right: 622px;
  }
}

.dropdown.text-end {
  padding: 34px;
}

.dropdown.text-end .new-prescription, .dropdown.text-end .message {
  margin-right: 25px;
}

.search-patient {
  text-align: center;
  background: var(--neutral-grey, #f9f9f9);
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 50px;
}

.search-patient h2 {
  color: var(--neutral-black, #2c2c2c);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

#form12 {
  width: 500px;
  margin: 20px auto auto;
}

.table th {
  color: var(--neutral-dark-grey, #aeaeae);
  background: var(--neutral-grey, #f9f9f9);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.badge-followed {
  color: green;
  text-align: center;
  border: 1px solid green;
  border-radius: 20px;
  width: 100px;
  padding: 5px;
  font-size: 15px;
  display: block;
}

.badge-non-followed {
  color: red;
  text-align: center;
  border: 1px solid red;
  border-radius: 20px;
  width: 100px;
  padding: 5px;
  font-size: 15px;
  display: block;
}

.logout {
  cursor: pointer;
  margin-left: 20px;
}

.btn.new-prescription {
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  display: inline-flex;
}

.table.bg-white td {
  height: 70px;
}

.align-items-center {
  height: 100%;
  align-items: center !important;
}

.container.recherche-patient {
  height: 90px;
}

.messageIcon {
  display: none;
}

#dropdownUser1 {
  border: 2px solid;
  border-radius: 100%;
  padding: 4px;
  font-weight: bold;
  text-decoration: none;
}

.message-searchAPA {
  margin-right: 25px;
}

.search-patient .address {
  display: flex !important;
}

.pagination {
  float: right;
  margin-top: 21px;
  margin-right: 113px;
}

.pagination .pagination-button {
  color: #0d6efd;
  background: #fff;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  width: 58px;
}

.pagination .pagination-button.active {
  color: #fff;
  background: #0d6efd;
  border: 1px solid #fff;
}

@media (width >= 1920px) {
  .pagination {
    margin-right: 300px;
  }
}

.searchAPA .container.centre {
  width: 100% !important;
}

.searchAPA .address {
  text-align: center;
  background: var(--neutral-grey, #f9f9f9);
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 50px;
}

.searchAPA .address .form .btn {
  width: 170px;
  margin-top: 25px;
}

.leaflet-container {
  width: 100%;
  height: 500px;
}

.css-b62m3t-container {
  width: 500px;
  margin: 50px auto auto;
}

.resultNotFound {
  text-align: center;
  background: #f9f9f9;
  padding: 15px;
  font-size: 20px;
}

.slider {
  width: 500px;
  margin: 50px auto auto;
  padding: 5px;
  overflow: hidden;
}

.slider input {
  appearance: none;
  height: 17x;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  width: 500px;
  box-shadow: 0 1px 1px #fff, inset 0 1px 1px #ddd;
}

.slider input::-webkit-slider-thumb {
  appearance: none;
  background: #0d6efd;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  position: relative;
  box-shadow: 0 0 2px #ddd;
}

#tickmarks {
  counter-reset: step -5;
  height: 20px;
  margin: 0;
  padding: 10px 0 0;
  list-style: none;
}

#tickmarks li {
  float: left;
  box-sizing: border-box;
  width: 7.6%;
  height: 10px;
  padding-left: 10px;
  position: relative;
}

#tickmarks li:first-child {
  margin-left: -16px;
}

#tickmarks li:nth-child(2) {
  margin-left: 8px;
}

#tickmarks li:nth-child(3) {
  margin-left: 5px;
}

#tickmarks li:nth-child(4) {
  margin-left: 8px;
}

#tickmarks li:nth-child(5) {
  margin-left: 17px;
}

#tickmarks li:nth-child(6) {
  margin-left: 8px;
}

#tickmarks li:nth-child(7), #tickmarks li:nth-child(8), #tickmarks li:nth-child(9) {
  margin-left: 9px;
}

#tickmarks li:nth-child(10) {
  margin-left: 19px;
}

#tickmarks li:before {
  content: counter(step);
  counter-increment: step 5;
  font-size: 15px;
  position: absolute;
  top: -6px;
}

.rayon {
  font-weight: 500;
}

.form-control.searchaddress {
  width: 500px;
  margin: 40px auto auto;
}

.searchAPA h2 {
  color: var(--neutral-black, #2c2c2c);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.p-3.mb-3.sapa {
  height: 127px;
}

.react-confirm-alert-button-group > button {
  background: #0d6efd !important;
  width: 150px !important;
  margin: auto !important;
}

.react-confirm-alert-button-group > .logout-no {
  color: #0d6efd;
  border: 1px solid #0d6efd;
  background: #fff !important;
}

.message {
  display: none;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.header-goal {
  border-bottom: 1px solid #80808014;
  padding: 18px;
}

.header-goal img {
  position: absolute;
}

.header-goal h5 {
  text-align: center;
}

.body h5 {
  color: #000;
  text-align: center;
  margin-bottom: 15px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.body .detail {
  color: var(--neutral-dark-grey, #aeaeae);
  text-align: center;
  width: 341px;
  margin: auto auto 20px;
  padding-top: 6px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Inter;
}

.list-goal ul li {
  border: 1px solid #80808029;
  border-radius: 8px;
  width: 314px;
  margin: auto auto 15px;
  padding: 7px;
  font-family: Inter;
  font-size: 15px;
}

.list-goal ul li img {
  margin-right: 15px;
}

.goal-confirm {
  width: 300px;
  margin-top: 31px;
}

.goal-button {
  text-align: center;
}

.later {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}

.list-goal ul {
  padding-left: 1rem !important;
}

#goal {
  height: 750px;
}

.list-goal ul li.active {
  color: #4282ff;
  border: 1px solid #4282ff;
}

#goal .body {
  margin: auto;
}

#goal .more {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

#goal .details-goal {
  border: 1px solid #80808057;
  border-radius: 7px;
  height: 144px;
  margin-top: 50px;
  padding: 17px;
  width: 310px !important;
}

.confirm-goal h6 {
  text-align: center;
  margin-top: 25px;
}

.goal-confirmed {
  text-align: center;
  color: #0d6efd;
  border: 1px solid #80808029;
  border-radius: 8px;
  width: 350px;
  margin: 30px auto auto;
  padding: 26px;
}

.confirm-goal .detail {
  text-align: center;
  color: gray;
  margin-top: 27px;
  font-size: 16px;
  font-weight: 400;
}

.confirm-goal .heart-circle {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
  padding-top: 21px;
}

.details-goal-p {
  text-align: center;
}

.list-goal ul {
  list-style: none;
}

.inputOther {
  text-align: center;
}

.inputOther input {
  border: 1px solid #4282ff;
  border-radius: 5px;
  width: 314px;
  padding: 15px;
}

.detail-other {
  color: #0d6efd;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}

.checkbox-goal {
  width: 50px;
  height: 20px;
}

.list-goal li {
  display: flex;
}

#goal .later {
  cursor: pointer;
}

@media (width >= 765px) {
  .body .detail, .list-goal ul li {
    margin: auto;
  }

  .list-goal ul li {
    margin-bottom: 20px;
  }

  .goal-confirmed {
    width: 416px;
  }

  .confirm-goal .heart-circle {
    padding-top: 21px;
  }

  .goal-confirm {
    width: 300px;
    margin-top: 11px;
  }
}

@media (width <= 768px) {
  .logo-auth {
    margin-left: 41px;
  }

  .col.forgot-password, .col.justify-content-center, .text-center {
    font-size: 13px;
  }
}

@media (width <= 990px) {
  .logo-auth {
    margin-left: 4px;
  }
}

#reader {
  border-radius: 6px;
  width: 90%;
  margin: 50px auto auto;
  padding: 15px;
  box-shadow: 0 0 8px #4282ff;
  border: 1px solid #4282ff00 !important;
}

.header-mobile-instance {
  z-index: 5;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.header-mobile-2 {
  background-image: url("icon-time-calendar.a74c6303.svg") !important;
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
  top: 15px !important;
  left: 242px !important;
}

.welcome-eapa {
  text-align: center;
  margin-top: 150px;
  position: relative;
}

.text-wrapper-5 {
  font-size: 22px;
  font-weight: 500;
}

.p {
  color: #808080c2;
  margin-top: 0;
  padding: 15px;
}

.icon-arrow-arrow {
  margin: auto;
}

#html5-qrcode-button-camera-permission {
  color: #fff;
  background: #4282ff;
  border: 1px solid #4282ff;
  border-radius: 7px;
  padding: 7px;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

.scan-success {
  text-align: center;
}

.scan-success .date {
  margin-top: 130px;
  font-size: 20px;
}

.scan-success .image {
  margin-top: 50px;
  margin-bottom: 50px;
}

.scan-success .session {
  font-size: 19px;
}

.scan-success .footer {
  position: fixed;
  bottom: 0;
}

.scan-success .footer .support {
  background: #ededed;
  border-radius: 15px;
  margin: auto;
  padding: 15px;
}

.try {
  text-align: center;
  cursor: pointer;
  color: #fff;
  background: #0d6efd;
  border-radius: 10px;
  width: 300px;
  margin: auto;
  padding: 13px;
  font-size: 20px;
}

.scan-success .details {
  text-align: center;
  margin-top: 15px;
}

.sessionwrong {
  color: red;
}

.scanner {
  text-align: center;
}

.scanner .btn {
  width: 180px;
  height: 53px;
  margin-top: 75px;
  font-size: 16px;
}

@media (width >= 769px) {
  .header-mobile-instance {
    width: 100%;
  }

  .header-mobile-2 {
    right: 130px;
    left: initial !important;
  }
}

@media (width <= 768px) {
  .logo-auth {
    margin-left: 41px;
  }

  .col.forgot-password, .col.justify-content-center, .text-center {
    font-size: 13px;
  }
}

@media (width <= 990px) {
  .logo-auth {
    margin-left: 4px;
  }
}

.logo.default {
  margin-bottom: 41px;
}

.reinitialise {
  text-align: center;
  color: gray;
  margin-bottom: 50px;
}

.forgotten #email {
  width: 400px;
  margin: auto;
}

.forgotten .btn-primary {
  width: 350px;
  margin-left: 40px;
}

.forgotten .success {
  color: green;
  text-align: center;
  font-size: 16px;
}

.forgotten .error {
  color: red;
  text-align: center;
  font-size: 16px;
}

.forgotten .loading {
  text-align: center;
  margin: auto;
}

@media (width <= 756px) {
  .forgotten .btn-primary.send-link {
    width: 266px;
    margin-left: 15px;
  }

  .logo.default.forgot-password {
    margin-left: 40px;
  }
}

.reset .logo.default {
  margin-bottom: 41px;
}

.reset .reinitialise {
  text-align: center;
  color: gray;
  margin-bottom: 50px;
}

.reset .btn-primary {
  width: 350px;
  margin-left: 40px;
}

.reset .success {
  color: green;
  text-align: center;
  font-size: 16px;
}

.reset .error {
  color: red;
  text-align: center;
  font-size: 16px;
}

.reset .loading {
  text-align: center;
  margin: auto;
}

.reset .password-generator {
  margin: auto;
  width: 340px !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

@media (width <= 756px) {
  .reset .password-generator {
    width: 286px !important;
  }

  .reset .btn-primary {
    width: 283px;
    margin-left: 6px;
  }

  .logo.resetPasswordLogo {
    margin-left: 48px;
  }
}

.inscription-mobile.eapa {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.inscription-mobile.eapa .div-3 {
  background-color: #fff;
  border: 0;
  width: 393px;
  height: 1000px;
  position: relative;
  overflow: hidden;
}

.inscription-mobile.eapa .p {
  color: #000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  width: 263px;
  position: absolute;
  top: 94px;
  left: 57px;
}

.inscription-mobile.eapa .text-wrapper-2 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 263px;
  position: absolute;
  top: 186px;
  left: 28px;
}

.inscription-mobile.eapa .text-wrapper-3 {
  color: var(--neutralblack);
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  width: 263px;
  position: absolute;
  top: 472px;
  left: 28px;
}

.inscription-mobile.eapa .text-field-basic-instance {
  width: 340px !important;
  position: absolute !important;
  top: 236px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .design-component-instance-node {
  width: 340px !important;
  position: absolute !important;
  top: 304px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .text-field-basic-2 {
  width: 340px !important;
  position: absolute !important;
  top: 370px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .text-field-basic-3 {
  width: 340px !important;
  position: absolute !important;
  top: 521px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .text-field-basic-4 {
  width: 340px !important;
  position: absolute !important;
  top: 584px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .en-cr-ant-votre {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 289px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 771px;
  left: 52px;
}

.inscription-mobile.eapa .span {
  color: #aeaeae;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile.eapa .text-wrapper-4 {
  color: #4282ff;
  font-family: var(--CP-bold-font-family);
  font-size: var(--CP-bold-font-size);
  font-style: var(--CP-bold-font-style);
  font-weight: var(--CP-bold-font-weight);
  letter-spacing: var(--CP-bold-letter-spacing);
  line-height: var(--CP-bold-line-height);
}

.inscription-mobile.eapa .text-wrapper-5 {
  color: #4282ff;
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
}

.inscription-mobile.eapa .button-action-instance {
  width: 341px !important;
  position: absolute !important;
  top: 652px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .keyboard {
  backdrop-filter: blur(108.73px) brightness();
  background-color: #d1d5db;
  width: 375px;
  height: 291px;
  position: absolute;
  top: 859px;
  left: 9px;
}

.inscription-mobile.eapa .overlap {
  height: 283px;
  position: relative;
  top: 8px;
  left: 1px;
}

.inscription-mobile.eapa .home-indicator-instance {
  position: absolute !important;
  top: 249px !important;
  left: 0 !important;
}

.inscription-mobile.eapa .keys {
  width: 369px;
  height: 259px;
  position: absolute;
  top: 0;
  left: 2px;
}

.inscription-mobile.eapa .dictation {
  width: 15px;
  height: 25px;
  position: absolute;
  top: -2531px;
  left: -3044px;
}

.inscription-mobile.eapa .emoji {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -2532px;
  left: -3349px;
}

.inscription-mobile.eapa .overlap-group {
  width: 182px;
  height: 42px;
  position: absolute;
  top: -2772px;
  left: -3374px;
}

.inscription-mobile.eapa .return {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 88px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile.eapa .label-3 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 88px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile.eapa .space {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 182px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile.eapa .label-4 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 182px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile.eapa .element {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 87px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile.eapa .label-5 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 87px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile.eapa .delete {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 327px;
}

.inscription-mobile.eapa .key-light {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: -2880px;
  left: -3701px;
}

.inscription-mobile.eapa .label-6 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 42px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.inscription-mobile.eapa .delete-button {
  width: 23px;
  height: 17px;
  position: absolute;
  top: -2751px;
  left: -3361px;
}

.inscription-mobile.eapa .shift {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 0;
}

.inscription-mobile.eapa .overlap-group-wrapper {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile.eapa .symbol-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 44px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.inscription-mobile.eapa .symbol {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 21px;
}

.inscription-mobile.eapa .shift-2 {
  width: 19px;
  height: 16px;
  position: absolute;
  top: -2752px;
  left: -3359px;
}

.inscription-mobile.eapa .m {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 282px;
}

.inscription-mobile.eapa .div-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 34px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.inscription-mobile.eapa .symbol-2 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 7px;
}

.inscription-mobile.eapa .n {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 244px;
}

.inscription-mobile.eapa .symbol-3 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 9px;
}

.inscription-mobile.eapa .b {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 206px;
}

.inscription-mobile.eapa .symbol-4 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 10px;
}

.inscription-mobile.eapa .v {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 169px;
}

.inscription-mobile.eapa .c {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 131px;
}

.inscription-mobile.eapa .x {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 94px;
}

.inscription-mobile.eapa .z {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 56px;
}

.inscription-mobile.eapa .l {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 319px;
}

.inscription-mobile.eapa .symbol-5 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 11px;
}

.inscription-mobile.eapa .k {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 281px;
}

.inscription-mobile.eapa .j {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 244px;
}

.inscription-mobile.eapa .h {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 206px;
}

.inscription-mobile.eapa .g {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 169px;
}

.inscription-mobile.eapa .f {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 131px;
}

.inscription-mobile.eapa .d {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 94px;
}

.inscription-mobile.eapa .s {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 56px;
}

.inscription-mobile.eapa .a {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 19px;
}

.inscription-mobile.eapa .overlap-wrapper {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 337px;
}

.inscription-mobile.eapa .o {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 300px;
}

.inscription-mobile.eapa .symbol-6 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 8px;
}

.inscription-mobile.eapa .i {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 262px;
}

.inscription-mobile.eapa .symbol-7 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 14px;
}

.inscription-mobile.eapa .u {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 225px;
}

.inscription-mobile.eapa .y {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 187px;
}

.inscription-mobile.eapa .t {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 150px;
}

.inscription-mobile.eapa .r {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 112px;
}

.inscription-mobile.eapa .e {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 75px;
}

.inscription-mobile.eapa .w {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 37px;
}

.inscription-mobile.eapa .symbol-8 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 6px;
}

.inscription-mobile.eapa .q {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.inscription-mobile.eapa .header-mobile-instance {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.form-control-lg {
  font-size: 15px !important;
}

.sign-up {
  cursor: pointer;
}

.cgu {
  width: 341px !important;
  position: absolute !important;
  top: 583px !important;
  left: 17px !important;
}

.confidentialite {
  width: 341px !important;
  position: absolute !important;
  top: 634px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .sign-up {
  top: 729px !important;
}

.inscription-mobile.eapa .sign-up.btn-primary {
  color: #fff;
  background: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 12px;
  padding: 8px;
}

.text-centre {
  font-family: var(--body-1-bold-font-family);
  font-size: var(--body-1-bold-font-size);
  font-style: var(--body-1-bold-font-style);
  font-weight: var(--body-1-bold-font-weight);
  letter-spacing: var(--body-1-bold-letter-spacing);
  line-height: var(--body-1-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 454px;
  left: 28px;
}

.input-centre {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 9px;
  width: 334px;
  padding: 8px;
  position: absolute;
  top: 501px;
  left: 20px;
}

.suggestions {
  z-index: 5;
  background: #f9f7f7;
  border-radius: 0 0 10px 10px;
  width: 336px;
  position: absolute;
  top: 543px;
  left: 19px;
  padding-left: 0 !important;
}

.suggestions li {
  cursor: pointer;
  width: 320px;
  padding: 10px;
}

.suggestions li:hover {
  background: #eee8e8;
}

.onboarding-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.onboarding-mobile .div-2 {
  background-color: #fff;
  border: 0;
  width: 393px;
  height: 852px;
  position: relative;
  overflow: hidden;
}

.onboarding-mobile .text-wrapper {
  color: #000;
  font-family: var(--heading-1-mobile-font-family);
  font-size: var(--heading-1-mobile-font-size);
  font-style: var(--heading-1-mobile-font-style);
  font-weight: var(--heading-1-mobile-font-weight);
  letter-spacing: var(--heading-1-mobile-letter-spacing);
  line-height: var(--heading-1-mobile-line-height);
  text-align: center;
  width: 263px;
  position: absolute;
  top: 94px;
  left: 57px;
}

.onboarding-mobile .merci-de-rentrer {
  color: var(--neutraldark-grey);
  font-family: var(--body-1-font-family);
  font-size: var(--body-1-font-size);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  letter-spacing: var(--body-1-letter-spacing);
  line-height: var(--body-1-line-height);
  text-align: center;
  width: 280px;
  position: absolute;
  top: 170px;
  left: 56px;
}

.onboarding-mobile .group {
  width: 284px;
  height: 82px;
  position: absolute;
  top: 750px;
  left: 56px;
}

.onboarding-mobile .vous-n-avez-plus-de {
  color: var(--neutraldark-grey);
  font-family: var(--CP-light-font-family);
  font-size: var(--CP-light-font-size);
  font-style: var(--CP-light-font-style);
  font-weight: var(--CP-light-font-weight);
  letter-spacing: var(--CP-light-letter-spacing);
  line-height: var(--CP-light-line-height);
  text-align: center;
  width: 261px;
  position: absolute;
  top: 0;
  left: 10px;
}

.onboarding-mobile .text-wrapper-2 {
  color: var(--primary-1);
  font-family: var(--button-2-link-font-family);
  font-size: var(--button-2-link-font-size);
  font-style: var(--button-2-link-font-style);
  font-weight: var(--button-2-link-font-weight);
  letter-spacing: var(--button-2-link-letter-spacing);
  line-height: var(--button-2-link-line-height);
  text-align: center;
  white-space: nowrap;
  width: 280px;
  text-decoration: underline;
  position: absolute;
  top: 61px;
  left: 0;
}

.onboarding-mobile .button-action-instance {
  width: 341px !important;
  position: absolute !important;
  top: 354px !important;
  left: 17px !important;
}

.button-action-instance.onboarding {
  left: 17px !important;
}

.onboarding-mobile .keyboard {
  backdrop-filter: blur(108.73px) brightness();
  background-color: #d1d5db;
  width: 375px;
  height: 291px;
  position: absolute;
  top: 859px;
  left: 9px;
}

.onboarding-mobile .overlap {
  height: 283px;
  position: relative;
  top: 8px;
  left: 1px;
}

.onboarding-mobile .home-indicator-instance {
  position: absolute !important;
  top: 249px !important;
  left: 0 !important;
}

.onboarding-mobile .keys {
  width: 369px;
  height: 259px;
  position: absolute;
  top: 0;
  left: 2px;
}

.onboarding-mobile .dictation {
  width: 15px;
  height: 25px;
  position: absolute;
  top: -2531px;
  left: -1904px;
}

.onboarding-mobile .emoji {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -2532px;
  left: -2209px;
}

.onboarding-mobile .overlap-group {
  width: 182px;
  height: 42px;
  position: absolute;
  top: -2772px;
  left: -2234px;
}

.onboarding-mobile .return {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 88px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-mobile .label {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 88px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.onboarding-mobile .space {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 182px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-mobile .label-2 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 182px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.onboarding-mobile .label-wrapper {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 87px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-mobile .label-3 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 87px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.onboarding-mobile .delete {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 327px;
}

.onboarding-mobile .key-light {
  background-image: url("shift.a1198576.png");
  background-position: 50%;
  background-size: cover;
  width: 42px;
  height: 42px;
  position: absolute;
  top: -2880px;
  left: -2561px;
}

.onboarding-mobile .label-4 {
  color: #000;
  letter-spacing: -.32px;
  text-align: center;
  white-space: nowrap;
  width: 42px;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 10px;
  left: 0;
}

.onboarding-mobile .delete-button {
  width: 23px;
  height: 17px;
  position: absolute;
  top: -2751px;
  left: -2221px;
}

.onboarding-mobile .shift {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 0;
}

.onboarding-mobile .overlap-group-wrapper {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-mobile .symbol-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 44px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.onboarding-mobile .symbol {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 21px;
}

.onboarding-mobile .img {
  width: 19px;
  height: 16px;
  position: absolute;
  top: -2752px;
  left: -2219px;
}

.onboarding-mobile .m {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 282px;
}

.onboarding-mobile .div-wrapper {
  background-color: #fcfcfe;
  border-radius: 4.6px;
  width: 34px;
  height: 44px;
  position: relative;
  top: -1px;
  left: -1px;
  box-shadow: 0 1px #898a8d;
}

.onboarding-mobile .symbol-2 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 7px;
}

.onboarding-mobile .n {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 244px;
}

.onboarding-mobile .symbol-3 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 9px;
}

.onboarding-mobile .b {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 206px;
}

.onboarding-mobile .symbol-4 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 10px;
}

.onboarding-mobile .v {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 169px;
}

.onboarding-mobile .c {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 131px;
}

.onboarding-mobile .x {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 94px;
}

.onboarding-mobile .z {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 108px;
  left: 56px;
}

.onboarding-mobile .l {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 319px;
}

.onboarding-mobile .symbol-5 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 11px;
}

.onboarding-mobile .k {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 281px;
}

.onboarding-mobile .j {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 244px;
}

.onboarding-mobile .h {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 206px;
}

.onboarding-mobile .g {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 169px;
}

.onboarding-mobile .f {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 131px;
}

.onboarding-mobile .d {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 94px;
}

.onboarding-mobile .s {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 56px;
}

.onboarding-mobile .a {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 54px;
  left: 19px;
}

.onboarding-mobile .p {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 337px;
}

.onboarding-mobile .o {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 300px;
}

.onboarding-mobile .symbol-6 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 8px;
}

.onboarding-mobile .i {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 262px;
}

.onboarding-mobile .symbol-7 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 14px;
}

.onboarding-mobile .u {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 225px;
}

.onboarding-mobile .y {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 187px;
}

.onboarding-mobile .t {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 150px;
}

.onboarding-mobile .r {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 112px;
}

.onboarding-mobile .e {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 75px;
}

.onboarding-mobile .w {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 37px;
}

.onboarding-mobile .symbol-8 {
  color: #000;
  letter-spacing: -.55px;
  text-align: center;
  white-space: nowrap;
  font-family: SF Pro Text-Regular, Helvetica;
  font-size: 22.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 6px;
}

.onboarding-mobile .q {
  width: 32px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.onboarding-mobile .text-field-code-number {
  position: absolute !important;
  top: 259px !important;
  left: 41px !important;
}

.onboarding-mobile .header-mobile-instance {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.error-message {
  color: red;
  margin-left: 100px;
  position: absolute;
  top: 317px;
}

.onboarding {
  cursor: pointer;
}

.loader {
  margin-top: 311px !important;
  margin-left: 165px !important;
}

.resend-code {
  cursor: pointer;
}

.react-calendar {
  background: #fff;
  border: 1px solid #a0a096;
  width: 350px;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  margin: -.5em;
  display: flex;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: .5em;
}

.react-calendar, .react-calendar *, .react-calendar :before, .react-calendar :after {
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
  margin: 0;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
}

.react-calendar__navigation button {
  background: none;
  min-width: 44px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: .75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: .5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  font: inherit;
  justify-content: center;
  align-items: center;
  font-size: .75em;
  font-weight: bold;
  display: flex;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 2em .5em;
}

.react-calendar__tile {
  text-align: center;
  line-height: 16px;
  font: inherit;
  background: none;
  max-width: 100%;
  padding: 10px 6.6667px;
  font-size: .833em;
}

.react-calendar__tile:disabled {
  color: #ababab;
  background-color: #f0f0f0;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled, .react-calendar__decade-view__years__year--neighboringDecade:disabled, .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: #fff;
  background: #006edc;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.calendar-div .header-goal img {
  cursor: pointer;
}

@media (width >= 769px) {
  .react-calendar {
    width: 407px;
    max-width: 100%;
  }
}

.inscription-mobile.eapa .p.inscription-completer {
  width: 305px;
  top: 94px;
  left: 28px;
}

.inscription-mobile.eapa .text-wrapper-3.mot-de-passe {
  width: 263px;
  top: 221px;
  left: 45px;
}

.eapa .password-generator {
  width: 340px !important;
  position: absolute !important;
  top: 269px !important;
  left: 17px !important;
}

.inscription-mobile.eapa .sign-up.valider-mot-de-passe {
  top: 465px !important;
}

.header-password-eapa .rounded-circle.logout {
  display: none;
}

.div-3.mot-de-passe-div {
  height: 550px !important;
}

.react-calendar {
  border-radius: 10px;
  width: 350px;
  max-width: 100%;
  margin-top: 50px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  background: #a0a0960d !important;
  border: 1px solid #a0a0960d !important;
  margin: 50px auto auto !important;
}

.col-6.session {
  border: 1px solid var(--neutral-grey, #f9f9f9);
  border-radius: 8px;
  margin-top: 50px;
}

.col-6.session p {
  text-align: center;
  text-align: center;
  color: var(--neutral-dark-grey, #aeaeae);
  text-align: center;
  padding-top: 25px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.col-6.session p.done h5 {
  color: var(--secondary-green, #00aea4);
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.col-6.session p.planified h5 {
  color: var(--primary-1, #4282ff);
  text-align: center;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

.card-RDV-instance.calendar {
  border: 1px solid #006edc;
  margin: 50px auto auto;
}

.card-RDV-instance.calendar .maison-hygeia {
  text-align: center;
}

.calendar-div {
  height: 910px;
}

@media (width >= 769px) {
  .react-calendar {
    width: 407px;
    max-width: 100%;
  }
}

.login-psc {
  text-align: center;
  background: #00000012;
  height: 100vh;
  margin: 0 !important;
}

.login-psc p {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.logo-auth {
  margin-left: 110px;
  position: relative;
  width: 200px !important;
  height: 100px !important;
}

.login {
  text-align: center;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.login .btn {
  margin: auto;
}

@media (width <= 768px) {
  .logo-auth {
    margin-left: 41px;
  }

  .col.forgot-password, .col.justify-content-center, .text-center {
    font-size: 13px;
  }
}
/*# sourceMappingURL=index.d1cbf973.css.map */
